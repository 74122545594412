import { useState, useEffect, useContext } from "react";
import { debounce } from "debounce";
import moment from 'moment-timezone';
import 'moment/locale/es';
import {
    ScheduleWrapper,
    ScheduleWrapperTitle,
    ScheduleMainWrapper,
    ScheduleNavTools,
    SearchContainer,
    SearchInput,
    SearchIcon,
    SelectInput,
    SelectDateWrapper,
    SelectDateCont,
    EventsWrapper,
    SpacerWrapper,
    SelectsWrapper
} from './Schedule.styled'
import {
    DateTitle,
    DateText
} from '../EventInfo/EventInfo.styled'
// Lubrerias
import { useAlert } from 'react-alert'
// Componentes Generales
import Loader from './../Loader/Loader.component';
// Conexiones API
import { getCountries, getActivities, getSchedule } from "../../common/api-helper";
// Context
import { LocationContext } from '../../context/location.context';
import EventInfo from "../EventInfo/EventInfo.component";

const Schedule = () => {
    const today = moment();
    const alert = useAlert();
    // Parametros para inicializar agenda
    const [countries, setCountries] = useState([]);
    const [months, setMonths] = useState([]);
    const [activities, setActivities] = useState([]);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showMonths, setShowMonths] = useState(true);
    // Locacion
    const { location } = useContext(LocationContext)

    const [searchParams, setSearchParams] = useState({
        date: moment(),
        idCountry: 0,
        idActivity: 0,
        keyWord: ''
    })
    // Se inicializa la agenda
    useEffect(() => {
        const initSchedule = async () => {
            setLoading(true);
            try {
                // Obtener lista de paises
                const ReqCountries = await getCountries();
                setCountries(ReqCountries);
                // Obtener lista de actividades
                const ReqActivites = await getActivities();
                setActivities(ReqActivites);
                // Obtener meses
                const AuxMonths = [];
                for (let i = 0; i < 4; i++) {
                    const AuxDate = moment();
                    moment.locale('es')
                    AuxDate.add(i, 'M');
                    AuxMonths.push({
                        name: AuxDate.format("MMMM"),
                        number: AuxDate.month(),
                        year: AuxDate.year()
                    });
                }
                setMonths(AuxMonths);
            } catch (e) {
                console.log('Ocurrió un error al cargar agenda...', e);
                alert.error('Ocurrió un error, intenta de nuevo más tarde')
            }
        };
        initSchedule();
    }, [alert, setActivities, setCountries]);

    // Cambiar agenda con nuevos parametros de busqueda
    useEffect(() => {
        const loadSchedule = async () => {
            setLoading(true);
            try {
                // Calcular Fechas de inicio y termino
                const dateYear = searchParams.date.year();
                const dateMonth = searchParams.date.month();
                const dateDayStart = searchParams.date.format("DD");
                const dateDayEnd = searchParams.date.clone().endOf('month').format("DD");
                const dateTimeStart = `${dateYear}-${dateMonth + 1}-${dateDayStart}T00:00:00`;
                const dateTimeEnd = `${dateYear}-${dateMonth + 1}-${dateDayEnd}T23:59:59`;
                // Soliciutd al BE
                const reqEvents = await getSchedule(
                    {
                        ...searchParams,
                        dateTimeStart,
                        dateTimeEnd
                    },
                    location.country_code);
                setEvents(reqEvents);
            } catch (e) {
                console.log('Ocurrió un error al cargar agenda...', e);
                alert.error('Ocurrió un error, intenta de nuevo más tarde')
            } finally {
                setLoading(false);
            }
        };
        loadSchedule();
    }, [searchParams, alert, location, setEvents])

    //--------------------------------------------------
    // Eventos disparados por el usuario

    // Dropdown de seleccionador de paises
    const onSelectCountry = e => {
        const idCountry = Number(e.currentTarget.value);
        setSearchParams(
            {
                ...searchParams,
                idCountry,
            },
        );
    }
    // Dropdown de seleccionador de actividades
    const onSelectActivity = e => {
        const idActivity = Number(e.currentTarget.value);
        setSearchParams(
            {
                ...searchParams,
                idActivity,
            },
        );
    }
    // Menu de seleccionador de Mes
    const onSelectMonth = (month, year) => {
        const dayStart = (month > today.month() || year > today.year())
            ? 1
            : today.date();
        searchParams.date.month(month);
        searchParams.date.date(dayStart);
        searchParams.date.year(year);
        setSearchParams(
            { ...searchParams },
        );
    }

    const onSearchWord = e => {
        setShowMonths(false);
        setLoading(true);
        const keyWord = e.target.value;
        if (keyWord === '') {
            setShowMonths(true);
        }
        setSearchParams(
            {
                ...searchParams,
                keyWord
            }
        );
    }
    return (
        <ScheduleWrapper>
            <ScheduleWrapperTitle>Agenda</ScheduleWrapperTitle>
            <ScheduleMainWrapper>
                <ScheduleNavTools>
                    <SearchContainer>
                        <SearchInput
                            type="text"
                            placeholder="Buscar"
                            onChange={debounce(onSearchWord, 750)}
                        />
                        <SearchIcon className="fas fa-search" />
                    </SearchContainer>
                    <SelectsWrapper>
                        <SelectInput onChange={onSelectActivity}>
                            <option value="">Todas las actividades</option>
                            {
                                activities.map((activity, index) => (
                                    <option
                                        key={index}
                                        value={activity.id_actividad}
                                    >
                                        {activity.nombre_actividad}
                                    </option>
                                ))

                            }
                        </SelectInput>
                        <SelectInput onChange={onSelectCountry}>
                            <option value="">Todos los países</option>
                            {
                                countries.map((country, index) => (
                                    <option
                                        key={index}
                                        value={country.id_pais}
                                    >
                                        {country.nombre_pais}
                                    </option>
                                ))
                            }
                        </SelectInput>
                    </SelectsWrapper>
                </ScheduleNavTools>
                <SelectDateWrapper className={!showMonths ? 'hide' : ''}>
                    {
                        months.map(Month => (
                            <SelectDateCont
                                weight="bold"
                                key={Month.number}
                                onClick={() => onSelectMonth(Month.number, Month.year)}
                                className={Month.number === searchParams.date.month() ? 'active' : ''}
                            >
                                {Month.name}
                            </SelectDateCont>
                        ))
                    }
                </SelectDateWrapper>
                <EventsWrapper>
                    {loading
                        ? // Mostrar carga
                        <SpacerWrapper>
                            <Loader />
                        </SpacerWrapper>
                        : events.length === 0
                            ? // No hay eventos disponibles
                            <SpacerWrapper>
                                <DateTitle>¡Próximamente!</DateTitle>
                                <DateText>No se han encontrado eventos con esos criterios, prueba con otros filtros</DateText>
                            </SpacerWrapper>
                            : // Mostrar eventos
                            events.map((event, index) => (
                                < EventInfo
                                    key={index}
                                    idEvent={event.id_evento}
                                    startDate={event.fecha_inicio}
                                    timeZone={event.pais.zonahoraria}
                                    image={event.url_imagen_evento}
                                    isSubtitleActive={searchParams.keyWord !== ''}
                                    description={event.descripcion}
                                    title={event.titulo}
                                    callToAction={event.accion}
                                    register={event.link_registro}
                                    books={event.libros}
                                />
                            ))
                    }
                </EventsWrapper>
            </ScheduleMainWrapper>
        </ScheduleWrapper >
    );
}

export default Schedule;