import { useState, useEffect, useContext } from 'react';
import { useAlert } from 'react-alert';
// Api
import { getFestival } from '../api-helper';
// Context
import { LocationContext } from '../../context/location.context';

const useFestival = (idFestival) => {
    const [festival, setFestival] = useState({});
    const [loading, setLoading] = useState(true);
    const alert = useAlert();
    const { location } = useContext(LocationContext);

    // Inicializar componentes del festival
    useEffect(() => {
        const loadFestival = async () => {
            setLoading(true);
            try {
                if (Object.keys(location).length > 0) {
                    // Cargar datos del festival
                    const request = await getFestival(idFestival, location.country_code);
                    if (request.hasOwnProperty('error')) {
                        setFestival({ error: true });
                    } else {
                        setFestival({
                            ...request,
                            error: false
                        });
                    }
                }
            } catch (e) {
                console.log('Ocurrió un error al cargar festival...', e);
                alert.error('Ocurrió un error, intenta de nuevo más tarde')
            } finally {
                setLoading(false);
            }
        }
        loadFestival();
    }, [alert, setFestival, idFestival, location]);

    return { festival, loading };
}

export default useFestival;