import { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
// Calendario
import ICalendarLink from "react-icalendar-link";
// Redes sociales
import { FacebookShareButton, TwitterShareButton } from 'react-share';
// Carousel
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../common/carousel.custom.css";
// Estilos
import {
    EventModalWrapper,
    EventModalCloseButton,
    EventInfoWrapper,
    EventDetailsWrapper,
    EventBooksWrapper,
    EventDefaultImageWrapper,
    EventDefaultImage,
    BookWrapper,
    BookContainer,
    EventTitle,
    EventDescription,
    EventExtraInfoWrapper,
    EventPlaceTimeList,
    EventPlaceTimeItem,
    CalendarWrapper,
    SocialMediaWrapper,
    SocialMediaContainer,
    RowButtonWrapper,
    MainButton
} from './EventModal.styled'
// Componentes generales
import FloatArrowComponent from '../common/FloatArrow.component';
import Loader from '../../components/Loader/Loader.component';
// Assets
import { ReactComponent as Facebook } from './../../assets/icons/Facebook.svg';
import { ReactComponent as Twitter } from './../../assets/icons/Twitter.svg';
import { ReactComponent as CalendarAdd } from './../../assets/icons/calendar-add.svg';
// API
import { getEvent } from '../../common/api-helper';
// Utilidades
import { getDateWithFormat, getTimeWithFormat } from '../../utilities/utils';

const EventModal = ({ idEvent, onClose }) => {
    const [infoEvent, setInfoEvent] = useState({});
    const [calendarEvent, setCalendarEvent] = useState({})
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    useEffect(() => {
        const loadEvent = async () => {
            try {
                // Informacion para componenente
                const event = await getEvent(idEvent);
                setInfoEvent({ ...event });
                // Informacion para calendario
                const startTime = moment.tz(event.fecha_inicio, event.pais.zona_horaria).format();
                const endTime = moment.tz(event.fecha_fin, event.pais.zona_horaria).format();
                setCalendarEvent({
                    title: 'Penguin Eventos',
                    description: event.titulo,
                    startTime,
                    endTime,
                });
            } catch (e) {
                console.log(`Ocurrió un error:\n${e}`);
                alert.error('Ocurrió un error, intenta de nuevo más tarde')
            } finally {
                setLoading(false);
            }
        }
        loadEvent();
        return () => {
            setInfoEvent({});
            setCalendarEvent({});
        }
    }, [idEvent, setInfoEvent, setCalendarEvent]);

    // Mientras se obtiene información del evento
    if (loading) {
        return (
            <EventModalWrapper style={{ padding: "10vh 0" }}>
                <Loader />
            </EventModalWrapper>
        )
    }
    // Formatear campos del componente
    const isPresencial = infoEvent.lugar;
    const isOnline = infoEvent.hasOwnProperty('streaming');
    const formatEvent = (isPresencial && isOnline)
        ? "Híbrido"
        : isPresencial
            ? "Presencial"
            : isOnline ?
                "Online"
                : null;
    return (
        <EventModalWrapper>
            <EventModalCloseButton
                onClick={onClose}
            />
            <EventInfoWrapper>
                <EventDetailsWrapper>
                    <EventTitle>
                        {infoEvent.titulo}
                    </EventTitle>
                    <EventDescription style={{marginBottom: "10px"}}>
                        {infoEvent.descripcion?.split("\n").map((text, index) => (
                            <span key={index}>
                                {text}
                                <br />
                            </span>
                        ))}
                    </EventDescription>
                    <EventPlaceTimeList>
                        <EventPlaceTimeItem>
                            {getDateWithFormat(infoEvent.fecha_inicio, infoEvent.pais.zona_horaria)}
                        </EventPlaceTimeItem>
                        <EventPlaceTimeItem style={{marginBottom: "15px"}}>
                            {
                                infoEvent.pais.codigo + " " +
                                getTimeWithFormat(infoEvent.fecha_inicio, infoEvent.pais.zona_horaria)
                                + " h"
                            }
                        </EventPlaceTimeItem>
                        <EventPlaceTimeItem>
                            {`Actividad: ${infoEvent.actividad.nombre_actividad}`}
                        </EventPlaceTimeItem>
                        {
                            formatEvent
                                ? <EventPlaceTimeItem>
                                    {`Tipo de Evento: ${formatEvent}`}
                                </EventPlaceTimeItem>
                                : <></>
                        }
                        {
                            formatEvent === 'Híbrido' || formatEvent === 'Presencial'
                                ? <EventPlaceTimeItem>
                                    {`Lugar: ${infoEvent.lugar.direccion}`}
                                </EventPlaceTimeItem>
                                : <></>
                        }
                    </EventPlaceTimeList>
                    <EventExtraInfoWrapper>
                        <CalendarWrapper>
                            <ICalendarLink event={calendarEvent}>
                                <EventDescription>Agregar a mi calendario</EventDescription>
                                <CalendarAdd
                                    width="40px"
                                />
                            </ICalendarLink>
                        </CalendarWrapper>
                        <SocialMediaWrapper>
                            <EventDescription>Compartir en:</EventDescription>
                            <SocialMediaContainer>
                                <FacebookShareButton
                                    url={`${process.env.REACT_APP_INI_PAGE_URL}${location.pathname}`}
                                    resetButtonStyle={false}
                                >
                                    <Facebook width="25px" height="25px" />
                                </FacebookShareButton>
                                <TwitterShareButton
                                    url={`${process.env.REACT_APP_INI_PAGE_URL}${location.pathname}`}
                                    resetButtonStyle={false}
                                >
                                    <Twitter width="25px" height="25px" />
                                </TwitterShareButton>
                            </SocialMediaContainer>
                        </SocialMediaWrapper>
                    </EventExtraInfoWrapper>
                </EventDetailsWrapper>
                <RowButtonWrapper>
                    {
                        !infoEvent.link_compra
                            ? <MainButton
                                type="secondary"
                                onClick={() => {
                                    window.open(infoEvent.link_compra, '_blank').focus();
                                }}
                            >
                                Compra el libro
                            </MainButton>
                            : <></>
                    }
                    <MainButton type="primary" onClick={() => {
                        window.open(infoEvent.link_registro, '_blank').focus();
                    }}>
                        {infoEvent.call_to_action}
                    </MainButton>
                </RowButtonWrapper>
            </EventInfoWrapper>
            {
                infoEvent.libros.length > 0
                    ? <EventBooksWrapper>
                        <Carousel
                            showThumbs={false}
                            dynamicHeight={false}
                            autoPlay={false}
                            infiniteLoop={false}
                            showStatus={false}
                            swipeable={true}
                            showIndicators={false}
                            renderArrowPrev={(clickHandler, hasPrev) => (
                                hasPrev
                                    ? <FloatArrowComponent
                                        onClick={clickHandler}
                                        visible={true}
                                        className="previous mini"
                                    />
                                    : <></>
                            )}
                            renderArrowNext={(clickHandler, hasNext) => (
                                hasNext
                                    ? <FloatArrowComponent
                                        onClick={clickHandler}
                                        visible={true}
                                        className="mini"
                                    />
                                    : <></>
                            )}
                        >
                            {
                                infoEvent.libros.map((book, index) => (
                                    <BookWrapper
                                        key={index}
                                    >
                                        <BookContainer src={book.image_url} />
                                    </BookWrapper>
                                ))
                            }
                        </Carousel>
                    </EventBooksWrapper >
                    : <EventDefaultImageWrapper>
                        <EventDefaultImage src={infoEvent.url_imagen} alt="Evento" />
                    </EventDefaultImageWrapper>
            }
        </EventModalWrapper >
    )
}
// Documentacion con PropTypes
EventModal.propTypes = {
    idEvent: PropTypes.number.isRequired,
}
export default EventModal;