import { useContext } from 'react';
import { useParams } from 'react-router-dom';
// Hooks
import useFestival from '../../common/hooks/useFestival';
// Context
import { ModalContext } from '../../context/modal.context';
// Componentes
import Loader from '../../components/Loader/Loader.component';
import Cookies from '../../components/Cookies/Cookies.component';
import ScheduleFestival from '../../components/ScheduleFestival/ScheduleFestival.component';
import ModalWindow from '../../components/ModalWindow/ModalWindow.component';
// Estilos
import {
  MainAnchor,
  PageWrapper,
  LoaderWrapper,
  MainErrorText,
} from '../Page.styled';
import {
  MainImagaWrapper,
  MainImage,
} from './Festival.styled'
// Assets
import Background from '../../assets/libraryRes.jpg';

const Festival = () => {
  const { id } = useParams();
  const { festival, loading, location } = useFestival(id);
  // Context de cookies
  const { cookiesAreAccepted } = useContext(ModalContext);

  return (
    <>
      <ModalWindow
        className={!cookiesAreAccepted ? 'visible' : ''}
        content={<Cookies />}
      />
      {loading
        ? <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
        : !festival.error
          ? <PageWrapper>
            <MainImagaWrapper background={Background}>
              <MainImage src={festival.url_imagen} />
            </MainImagaWrapper>
            <ScheduleFestival
              festival={festival}
              location={location} />
          </PageWrapper>
          : <PageWrapper>
            <MainErrorText>
              No se encontro el festival solicitado :(
            </MainErrorText>
            <MainAnchor to="/">¿Buscamos algo?</MainAnchor>
          </PageWrapper>
      }
    </>
  );
}

export default Festival;