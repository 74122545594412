import { useState, useEffect, useContext } from "react";
import { debounce } from "debounce";
import { useAlert } from 'react-alert'
import moment from 'moment-timezone';
import 'moment/locale/es';
// Animacion al expandir
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import {
    ScheduleMainWrapper,
    ScheduleNavTools,
    SearchContainer,
    SearchInput,
    SearchIcon,
    SelectDateWrapper,
    SelectDateCont,
    EventsWrapper,
    SpacerWrapper,
} from '../Schedule/Schedule.styled'
import {
    EventContainer,
    BasicWrapper,
    OnlyImageWrapper,
    EventImage,
    DateTitle,
    DateText,
    DateSubTitle,
    InfoContainer,
    InfoTitle,
    MainButton,
    BooksContainer,
    BookImage,
    ArrowContainer,
} from '../EventInfo/EventInfo.styled'
// Componentes Generales
import Loader from '../Loader/Loader.component';
import ModalWindow from '../../components/ModalWindow/ModalWindow.component';
import EventModal from '../../components/EventModal/EventModal.component';
// Conexiones API
import { getSchedule } from "../../common/api-helper";
// Context
import { LocationContext } from '../../context/location.context';
import { ModalContext } from '../../context/modal.context';

const EventItemSchedule = ({ event, isSubtitleActive, onClick }) => {
    const [showBooks, setShowBooks] = useState(false);

    const eventTimeZone = event.pais.zonahoraria;
    // Formatear numeros en fechas
    const originalStartDate = moment.tz(event.fecha_inicio, eventTimeZone);
    const originalEndDate = moment.tz(event.fecha_fin, eventTimeZone);
    // Obtener zona horaria del navegador
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const eventDateStart = originalStartDate.clone().tz(currentTimeZone);
    const eventDateEnd = originalEndDate.clone().tz(currentTimeZone);
    // Obtener fecha con formato
    moment.locale('es');
    const EvDay = eventDateStart.format("DD");
    const EvMonthName = eventDateStart.format("MMMM");
    // Formatear numeros en fechas
    const HoursStart = eventDateStart.format("kk");
    const MinsStart = eventDateStart.format("mm");

    const HoursEnd = eventDateEnd.format("kk");
    const MinsEnd = eventDateEnd.format("mm");

    // Funcion para call to action
    const onCall = () => { window.open(event.link_registro, '_blank').focus(); }
    const onBuy = () => { window.open(event.link_compra, '_blank').focus(); }
    // Funcion para mostrar libros
    const onShowBooks = e => { setShowBooks(!showBooks) }

    return (
        <EventContainer >
            <BasicWrapper>
                <OnlyImageWrapper>
                    <EventImage
                        src={event.url_imagen_evento}
                        onClick={onClick}
                    />
                </OnlyImageWrapper>
                <InfoContainer className="large">
                    <InfoTitle
                        className="first"
                        onClick={onClick}
                    >
                        {event.nombre_actividad}
                    </InfoTitle>
                    <InfoTitle
                        onClick={onClick}
                    >
                        {event.titulo}
                    </InfoTitle>
                </InfoContainer>
                <InfoContainer>
                    <DateSubTitle active={isSubtitleActive} className="bottom-child">
                        {EvDay} de {EvMonthName}
                    </DateSubTitle>
                    <InfoTitle>
                        {HoursStart}:{MinsStart} h - {HoursEnd}:{MinsEnd} h
                    </InfoTitle>
                </InfoContainer>
                <MainButton
                    type="secondary"
                    className={
                        !event.link_compra
                            ? 'first'
                            : 'first no-visible'
                    }
                    onClick={onBuy}
                >
                    Compra el libro
                </MainButton>
                <MainButton type="primary" onClick={onCall}>
                    {event.accion}
                </MainButton>
                <ArrowContainer
                    onClick={onShowBooks}
                    hasBooks={event.libros.length > 0}
                    className={showBooks ? 'active' : ''}
                >
                    <i className="fas fa-chevron-down"></i>
                </ArrowContainer>
            </BasicWrapper>
            <SlideDown
                closed={!showBooks}
            >
                <BooksContainer>
                    {event.libros.map((book, index) => (
                        <BookImage
                            key={index}
                            src={book}
                        />
                    ))}
                </BooksContainer>
            </SlideDown>
        </EventContainer>
    );
}

const ScheduleFestival = ({ festival }) => {
    const { fecha_inicio, fecha_fin } = festival;
    const alert = useAlert();
    // Parametros para inicializar agenda
    const [days, setDays] = useState([]);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showMonths, setShowMonths] = useState(true);
    const [idModalEvent, setIdModalEvent] = useState(0);
    // Locacion
    const { location } = useContext(LocationContext);
    // Context Cookies, detalle del evento
    const { showEventsDetails, setShowEventsDetails } = useContext(ModalContext);


    const [searchParams, setSearchParams] = useState({
        date: moment(fecha_inicio),
        idFestival: Number(festival.id_festival),
        keyWord: ''
    })
    // Se inicializa la agenda
    useEffect(() => {
        const initSchedule = async () => {
            setLoading(true);
            try {
                // Obtener fechas
                const dateCurr = moment(fecha_inicio);
                const dateEnd = moment(fecha_fin);
                // Llenar dias
                const listDays = [];
                for (let i = 0; dateCurr <= dateEnd; i++) {
                    listDays.push({
                        nameMonth: dateCurr.format("MMMM"),
                        numberMonth: dateCurr.month(),
                        numberDay: dateCurr.date(),
                        year: dateCurr.year()
                    });
                    dateCurr.add(1, 'd');
                }
                setDays(listDays);
            } catch (e) {
                console.log('Ocurrió un error al inicializar agenda festival...', e);
                alert.error('Ocurrió un error, intenta de nuevo más tarde')
            } finally {
                setLoading(false);
            }
        };
        initSchedule();
    }, [fecha_inicio, fecha_fin, alert]);

    // Cambiar agenda con nuevos parametros de busqueda
    useEffect(() => {
        const loadSchedule = async () => {
            setLoading(true);
            try {
                // Calcular Fechas de inicio y termino
                const dateYear = searchParams.date.year();
                const dateMonth = searchParams.date.month();
                const dateDay = searchParams.date.date();
                const dateTimeStart = `${dateYear}-${dateMonth + 1}-${dateDay}T00:00:00`;
                const dateTimeEnd = `${dateYear}-${dateMonth + 1}-${dateDay}T23:59:59`;
                // Soliciutd al BE
                const reqEvents = await getSchedule(
                    {
                        ...searchParams,
                        dateTimeStart,
                        dateTimeEnd
                    },
                    location.country_code);
                setEvents(reqEvents);
            } catch (e) {
                console.log('Ocurrió un error al cargar agenda festival...', e);
                alert.error('Ocurrió un error, intenta de nuevo más tarde')
            } finally {
                setLoading(false);
            }
        };
        loadSchedule();
        return (() => {
            setEvents([]);
        })
    }, [searchParams, alert, location])

    //--------------------------------------------------
    // Eventos disparados por el usuario

    // Menu de seleccionador de Mes
    const onSelectDay = ({ numberDay, numberMonth, year }) => {
        searchParams.date.date(numberDay);
        searchParams.date.month(numberMonth)
        searchParams.date.year(year)
        setSearchParams(
            { ...searchParams },
        );
    }

    // Buscar palabra clave
    const onSearchWord = e => {
        setShowMonths(false);
        setLoading(true);
        const keyWord = e.target.value;
        if (keyWord === '') {
            setShowMonths(true);
        }
        setSearchParams(
            {
                ...searchParams,
                keyWord
            }
        );
    }

    // Abrir modal de detalle
    const onOpenModal = id => {
        setIdModalEvent(Number(id));
        setShowEventsDetails(true);
    }
    return (
        <>
            {
                showEventsDetails
                    ? <ModalWindow
                        className="lg visible"
                        isVisible={true}
                        onClickOut={() => {
                            setShowEventsDetails(false);
                        }}
                    >
                        <EventModal
                            idEvent={idModalEvent}
                            onClose={() => {
                                setShowEventsDetails(false);
                            }}
                        />
                    </ModalWindow>
                    : <></>
            }
            <ScheduleMainWrapper>
                <ScheduleNavTools>
                    <SearchContainer>
                        <SearchInput
                            type="text"
                            placeholder="Buscar"
                            onChange={debounce(onSearchWord, 750)}
                        />
                        <SearchIcon className="fas fa-search" />
                    </SearchContainer>
                </ScheduleNavTools>
                <SelectDateWrapper className={!showMonths ? 'hide overflow' : 'overflow'}>
                    {
                        days.map((date, index) => (
                            <SelectDateCont
                                weight="700"
                                key={index}
                                onClick={() => onSelectDay(date)}
                                className={date.numberDay === searchParams.date.date() ? 'active fixed' : 'fixed'}
                            >
                                Día {index + 1}
                                <span>
                                    {date.numberDay} {date.nameMonth} {date.year}
                                </span>
                            </SelectDateCont>
                        ))
                    }
                </SelectDateWrapper>
                <EventsWrapper>
                    {loading
                        // Mostrar carga
                        ? <SpacerWrapper>
                            <Loader />
                        </SpacerWrapper>
                        : events.length === 0
                            ? // No hay eventos disponibles
                            <SpacerWrapper>
                                <DateTitle>¡Próximamente!</DateTitle>
                                <DateText>No se han encontrado eventos con esos criterios, prueba con otros filtros</DateText>
                            </SpacerWrapper>
                            : // Mostrar eventos
                            events.map((event, index) => (
                                <EventItemSchedule
                                    key={index}
                                    event={event}
                                    isSubtitleActive={searchParams.keyWord !== ''}
                                    onClick={e => { onOpenModal(event.id_evento) }}
                                />
                            ))
                    }
                </EventsWrapper>
            </ScheduleMainWrapper>
        </>
    );
}

export default ScheduleFestival;