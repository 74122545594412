export const ColorMainBackground = 'rgb(255, 255, 255)';

export const ColorPrimary = 'rgb(255, 111, 0)';
export const ColorPrimaryOpacity = 'rgba(255, 111, 0, 0.5)';

export const ColorWarning = 'rgb(255, 179, 58)';
export const ColorDefeat = 'rgb(255, 89, 89)';
export const ColorSuccess = 'rgb(30, 167, 76)';

export const ColorShadow = 'rgba(0, 0, 0, 0.15)';
export const ColorShadowDarker = 'rgba(37, 37, 37, 0.4)';

export const ColorGrayIcon = 'rgb(122, 122, 122)';
export const ColorDarkIcon = 'rgb(51, 48, 51)';