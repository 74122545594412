import PropTypes from "prop-types";

const VideoEmbeded = ({ embededLink, title }) => {
    return (
        <iframe
            width="100%"
            height="100%"
            src={`${embededLink}?modestbranding=1`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={title}
        />
    )
}

VideoEmbeded.propTypes = {
    embededLink: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default VideoEmbeded;