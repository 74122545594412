import styled from '@emotion/styled';
// Componentes Generales
import FullScreenContainer from '../common/FullScreenContainer.styled';
import FlexRowContainer from '../common/FlexRowContainer.styled';
import CustomScrollContainer from '../common/CustomScrollContainer';
// Colores
import {
    ColorGrayIcon,
    ColorPrimary,
    ColorShadow
} from '../common/Pallete.const';

export const ThisMonthWrapper = styled(FullScreenContainer)`
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    @media only screen and (min-width: 768px){
        margin-top: 20px;
    }
`;
export const ThisMonthWrapperTitle = styled.h5`
    width: 100%;
    text-align: center;
    font-weight: 800;
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 40px; 
    @media only screen and (min-width: 768px){
        font-size: 30px;
    }
    @media only screen and (min-width: 1024px){
        font-size: 50px;
    }
`;
export const EventsWrapper = styled(CustomScrollContainer)`
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: auto;
    padding: 20px 0;
    overflow-y: hidden;
    overflow-x: hidden;
    scroll-behavior: smooth;
`;
export const EventItem = styled.div`
    cursor: pointer;
    text-align: left;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 10px;
    transition: all ease-in-out 0.25s;
    min-width: 220px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px ${ColorShadow};
    &:first-of-type{margin-left: 20px;};
    &:last-of-type{margin-right: 20px;};
    &:hover{
        transform: scale(1.01);
    }
    @media only screen and (min-width: 500px){
        min-width: 250px;
        max-width: 250px;
    }
    @media only screen and (min-width: 768px){
        min-width: 300px;
        max-width: 300px;
        &:first-of-type{margin-left: 9vw;};
        &:last-of-type{margin-right: 9vw;};
    }
    @media only screen and (min-width: 1024px){
        min-width: 360px;
        max-width: 360px;
    }
    @media only screen and (min-width: 1920px){
        min-width: 490px;
    }
`;
export const ImageWrapper = styled.div`
    height: 220px;
    width: 100%;
    @media only screen and (min-width: 500px){
        height: 250px;
    }
    @media only screen and (min-width: 768px){
        height: 300px;
    }
    @media only screen and (min-width: 1024px){
        height: 360px;
    }
    @media only screen and (min-width: 1920px){
        height: 490px;
    }

`;
export const ImageContainer = styled.img`    
    object-fit: cover;
    height: 100%;
    width: 100%;
    object-position: 50% 50%;
`;
export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px 15px;
    @media only screen and (min-width: 500px){
        padding: 35px 20px;
    }
    @media only screen and (min-width: 768px){
        padding: 40px 25px;
    }
    @media only screen and (min-width: 1024px){
        padding: 40px 30px;
    }
    @media only screen and (min-width: 1920px){
        padding: 50px 40px;
    }
    flex: 1;
    justify-content: space-between;
`;
export const InfoWrapperFormat = styled.h6`
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 800;
    padding-bottom: 5px;
    color: ${ColorPrimary};
    @media only screen and (min-width: 768px){
        font-size: 20px;
    }
    @media only screen and (min-width: 1920px){
        font-size: 23px;
    }
`;
export const InfoWrapperTitle = styled.h6`
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 30px;
    position: relative;
    height: 85px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:after {
        content: '';
        position: absolute;
        bottom: 15px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: ${ColorPrimary};
    }
    @media only screen and (min-width: 768px){
        font-size: 22px;
        height: 95px;
    }
    @media only screen and (min-width: 1920px){
        font-size: 27px;
        height: 110px;
    }
`;
export const InfoWrapperType = styled.h3`
    font-size: 15px;
    margin-bottom: 20px;
    color: ${ColorPrimary};
    @media only screen and (min-width: 768px){
        font-size: 18px;
        margin-bottom: 25px;
    }
    @media only screen and (min-width: 1920px){
        font-size: 20px;
        margin-bottom: 30px;
    }

`;
export const DateInfoContainer = styled(FlexRowContainer)`
    justify-content: space-between;
    margin-bottom: 25px;
`;
export const InfoItem = styled.p`
    font-size: 13px;
    font-weight: 300;
    i{
        margin-right: 3px; 
        color: ${ColorGrayIcon};
    }
    margin-bottom: 25px;
    @media only screen and (min-width: 1024px){
        margin-bottom: 50px;
        font-size: 15px;
    }
`;
export const DescriptionContainer = styled.p`
    display: none;
    margin-bottom: 40px;
    font-weight: normal;
    @media only screen and (min-width: 768px){
        display: block;
        font-size: 15px;
    }
    @media only screen and (min-width: 1024px){
        font-size: 19px;
        margin-bottom: 50px;
    }
    @media only screen and (min-width: 1920px){
        font-size: 20px;
        margin-bottom: 80px;
    }
`;
export const ButtonsColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 10%;
    button:first-of-type{ margin-bottom: 20px; }
`;
