import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom';
// Styled Components
import {
    MainEventWrapper,
    InfoContainer,
    InfoContainerFormat,
    InfoContainerTitle,
    InfoContainerText,
    InfoContainerType,
    InfoContainerHighlight,
    InfoContainerHighlightWrapper,
    InfoContainerHighlightTitle,
    InfoContainerHighlightText,
    RowButtonWrapper,
    ImageContainer,
    EventImage,
    BookFloatContainerImage
} from './MainEvent.styled'
import ButtonAction from '../common/ButtonAction.styled';
// Utilidades
import { getDateWithFormat, getTimeWithFormat } from '../../utilities/utils';

const MainEvent = ({
    idEvent,
    activityName,
    format,
    title,
    description,
    startDate,
    callToAction,
    linkAction,
    linkBuy,
    srcPicture,
    books,
    country
}) => {
    const history = useHistory();
    const hasBook = books.length > 0;
    // Funciones "onClick"
    const onBuy = () => { window.open(linkBuy, '_blank').focus(); }
    const onCall = () => { window.open(linkAction, '_blank').focus(); }
    const onOpenModal = e => {
        e.preventDefault();
        history.push(`/${idEvent}`);
    }
    // Formatear Fecha y Hora
    const FormatDate = getDateWithFormat(startDate, country.zonahoraria);
    const FormatTime = getTimeWithFormat(startDate, country.zonahoraria);
    // Formatear autores
    let listAuthor = [];
    books.forEach((book) => {
        const auxList = book.authors.map((author) => (author.nombre));
        console.log(auxList);
        listAuthor = listAuthor.concat(auxList);
    });

    console.log(listAuthor);

    return (
        <MainEventWrapper>
            <InfoContainer>
                {
                    !format
                        ? <InfoContainerFormat>{format}</InfoContainerFormat>
                        : <></>
                }
                <InfoContainerTitle
                    onClick={onOpenModal}
                >
                    {title}
                </InfoContainerTitle>
                <InfoContainerType>{activityName}</InfoContainerType>
                <InfoContainerText>
                    {description.split("\n").map((text, index) => (
                        <span
                            key={index}
                        >
                            {text}
                            <br />
                        </span>
                    ))}
                </InfoContainerText>
                <div>
                    <InfoContainerHighlight>
                        {
                            hasBook
                                ? <InfoContainerHighlightWrapper style={{ flex: 2 }}>
                                    <InfoContainerHighlightTitle>
                                        {
                                            listAuthor.length > 1
                                                ? 'Autores'
                                                : 'Autor'
                                        }
                                    </InfoContainerHighlightTitle>
                                    <InfoContainerHighlightText>
                                        {listAuthor.reduce((acc, author, index) => `${acc}${index !== 0 ? ',' : ''} ${author}`, '')}
                                    </InfoContainerHighlightText>
                                </InfoContainerHighlightWrapper>
                                : <></>
                        }
                        <InfoContainerHighlightWrapper>
                            <InfoContainerHighlightTitle>Cuándo</InfoContainerHighlightTitle>
                            <InfoContainerHighlightText>{FormatDate}</InfoContainerHighlightText>
                        </InfoContainerHighlightWrapper>
                        <InfoContainerHighlightWrapper>
                            <InfoContainerHighlightTitle>Hora</InfoContainerHighlightTitle>
                            <InfoContainerHighlightText>{FormatTime} h</InfoContainerHighlightText>
                        </InfoContainerHighlightWrapper>
                    </InfoContainerHighlight>
                    <RowButtonWrapper>
                        {
                            !linkBuy
                                ? <ButtonAction
                                    type="secondary"
                                    onClick={onBuy}
                                >
                                    Compra el libro
                                </ButtonAction>
                                : <></>
                        }
                        <ButtonAction type="primary" onClick={onCall}>{callToAction}</ButtonAction>
                    </RowButtonWrapper>
                </div>
            </InfoContainer>
            <ImageContainer hasBook={hasBook}>
                <EventImage onClick={onOpenModal}>
                    <img src={srcPicture} alt="Autor" />
                </EventImage>
                {
                    hasBook
                        ? <BookFloatContainerImage
                            src={books[0].image_url}
                            alt="Portada Libro"
                        />
                        : <></>
                }
            </ImageContainer>
        </MainEventWrapper >
    );
}

// Documentacion con PropTypes
MainEvent.propTypes = {
    activityName: PropTypes.string.isRequired,
    format: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    callToAction: PropTypes.string.isRequired,
    linkAction: PropTypes.string.isRequired,
    srcPicture: PropTypes.string.isRequired,
}

export default MainEvent;