import moment from 'moment-timezone';
import 'moment/locale/es';

export const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
// Obtener zona horaria del navegador
const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
// Funcion para obtener fecha formateada
export const getDateWithFormat = (date, timeZone) => {
    const originalDate = moment.tz(date, timeZone);
    const convertedDate = originalDate.clone().tz(currentTimeZone);
    moment.locale('es')
    const FormatDate = convertedDate.format("DD") + " de " + convertedDate.format("MMMM");
    return FormatDate;
}
// Funcion para obtener hora formateada
export const getTimeWithFormat = (date, timeZone) => {
    const originalDate = moment.tz(date, timeZone);
    const convertedDate = originalDate.clone().tz(currentTimeZone);
    moment.locale('es')
    const FormatTime = convertedDate.format("kk:mm")
    return FormatTime;
}
