import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
// Estilos generales
import {
    PageWrapper,
    LoaderWrapper,
} from '../Page.styled';
// Componentes
import Loader from '../../components/Loader/Loader.component';
// Estilos
import {
    SectionTitle,
    MainEventsWrapper,
    MainEventContainer,
    NextEventsWrapper,
    NextEventContainer,
    MainEventDetailsWrapper,
    NextEventImageWrapper,
    MainEventDate,
    MainEventTitle,
    MainEventButton,
    NextEventImage,
    NextEventInfo,
    NextEventDate,
    NextEventTitle,
    NextEventDescription,
    NextEventButton,
    LastMonthWrapper,
    LastMonthItemWrapper,
    LastMonthItemContainer,
    LastMonthTitle,
    LastMonthDesc
} from './AllStreaming.styled'
// Hooks
import useAllStreaming from '../../common/hooks/useAllStreaming';
// Context
import { LocationContext } from '../../context/location.context';
// Assets
import { ReactComponent as IconPlay } from '../../assets/icons/play.svg'
// Utilidades
import { getDateWithFormat } from '../../utilities/utils';

const AllStreaming = () => {
    // Navegador
    const history = useHistory();
    // Locacion
    const { location } = useContext(LocationContext);
    // Hook Custom
    const { loading, lastEvents, nextEvents } = useAllStreaming(location?.country_code);
    const handleClickItem = id => {
        history.push(`detail/${id}`);
    }
    // Componente
    return (
        <>
            {
                loading
                    ? <LoaderWrapper>
                        <Loader />
                    </LoaderWrapper>
                    : <PageWrapper>
                        {
                            nextEvents.length > 0
                                ? <>
                                    <SectionTitle>
                                        Streaming
                                    </SectionTitle>
                                    <MainEventsWrapper>
                                        <MainEventContainer
                                            background={nextEvents[0]?.url_imagen}
                                        >
                                            <MainEventDetailsWrapper>
                                                <MainEventDate>
                                                    {getDateWithFormat(nextEvents[0]?.fecha_inicio, nextEvents[0]?.zonahoraria)}
                                                </MainEventDate>
                                                <MainEventTitle>
                                                    {nextEvents[0]?.titulo}
                                                </MainEventTitle>
                                                <MainEventButton
                                                    onClick={() => handleClickItem(nextEvents[0]?.id_evento)}
                                                >
                                                    Ver
                                                </MainEventButton>
                                            </MainEventDetailsWrapper>
                                        </MainEventContainer>
                                        {
                                            nextEvents.length >= 3
                                                ? <NextEventsWrapper>
                                                    <NextEventContainer>
                                                        <NextEventImageWrapper>
                                                            <NextEventImage
                                                                src={nextEvents[1]?.url_imagen}
                                                            />
                                                        </NextEventImageWrapper>
                                                        <NextEventInfo>
                                                            <NextEventDate>
                                                                {getDateWithFormat(nextEvents[1]?.fecha_inicio, nextEvents[1]?.zonahoraria)}
                                                            </NextEventDate>
                                                            <NextEventTitle>
                                                                {nextEvents[1]?.titulo}
                                                            </NextEventTitle>
                                                            <NextEventDescription>
                                                                {nextEvents[1]?.descripcion}
                                                            </NextEventDescription>
                                                            <NextEventButton
                                                                onClick={() => handleClickItem(nextEvents[1]?.id_evento)}
                                                            >
                                                                Ver
                                                            </NextEventButton>
                                                        </NextEventInfo>
                                                    </NextEventContainer>
                                                    <NextEventContainer>
                                                        <NextEventImageWrapper>
                                                            <NextEventImage
                                                                src={nextEvents[2]?.url_imagen}
                                                            />
                                                        </NextEventImageWrapper>
                                                        <NextEventInfo>
                                                            <NextEventDate>
                                                                {getDateWithFormat(nextEvents[2]?.fecha_inicio, nextEvents[2]?.zonahoraria)}
                                                            </NextEventDate>
                                                            <NextEventTitle>
                                                                {nextEvents[2]?.titulo}
                                                            </NextEventTitle>
                                                            <NextEventDescription>
                                                                {nextEvents[2]?.descripcion}
                                                            </NextEventDescription>
                                                            <NextEventButton
                                                                onClick={() => handleClickItem(nextEvents[2]?.id_evento)}
                                                            >
                                                                Ver
                                                            </NextEventButton>
                                                        </NextEventInfo>
                                                    </NextEventContainer>
                                                </NextEventsWrapper>
                                                : <></>
                                        }
                                    </MainEventsWrapper>
                                </>
                                : <></>
                        }
                        {
                            lastEvents.length > 0
                                ?
                                <>
                                    <SectionTitle>
                                        Último Mes
                                    </SectionTitle>
                                    <LastMonthWrapper>
                                        {
                                            lastEvents.map((e, index) => (
                                                <LastMonthItemWrapper
                                                    background={e.url_imagen}
                                                    key={index}
                                                >
                                                    <LastMonthItemContainer
                                                        onClick={() => { handleClickItem(e.id_evento) }}
                                                    >
                                                        <IconPlay />
                                                        <LastMonthTitle>
                                                            {e.titulo}
                                                        </LastMonthTitle>
                                                        <LastMonthDesc>
                                                            {e.descripcion}
                                                        </LastMonthDesc>
                                                    </LastMonthItemContainer>
                                                </LastMonthItemWrapper>
                                            ))
                                        }
                                    </LastMonthWrapper>
                                </>
                                : <></>
                        }
                    </PageWrapper>
            }
        </>
    )
}

export default AllStreaming;