import { useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
// Hooks
import useHome from '../../common/hooks/useHome';
// Context
import { ModalContext } from '../../context/modal.context';
// Componetes
import HeroCarousel from '../../components/Hero/Hero.component';
import MainEvent from '../../components/MainEvent/MainEvent.component';
import NextMonth from '../../components/ThisMonth/ThisMonth.component';
import NewsLetter from '../../components/NewsLetter/NewsLetter.component';
import Schedule from '../../components/Schedule/Schedule.component';
import NoContent from '../../components/NoContent/NoContent.component';
import Loader from '../../components/Loader/Loader.component';
import ModalWindow from '../../components/ModalWindow/ModalWindow.component';
import Cookies from '../../components/Cookies/Cookies.component';
import EventModal from '../../components/EventModal/EventModal.component';
// Estilos
import {
  PageWrapper,
  LoaderWrapper,
} from '../Page.styled';

const Landing = () => {
  const { idEvent } = useParams();
  const { components, loading } = useHome();
  const history = useHistory();
  // Context Cookies, detalle del evento
  const { cookiesAreAccepted, showEventsDetails, setShowEventsDetails } = useContext(ModalContext);
  // 
  useEffect(() => {
    if (typeof (idEvent) !== "undefined") {
      setShowEventsDetails(true);
    }
  }, [idEvent, setShowEventsDetails])

  return (
    <>
      {
        showEventsDetails
          ? <ModalWindow
            className="lg visible"
            isVisible={true}
            onClickOut={() => {
              history.goBack();
              setShowEventsDetails(false);
            }}
          >
            <EventModal
              idEvent={Number(idEvent)}
              onClose={() => {
                history.goBack();
                setShowEventsDetails(false);
              }}
            />
          </ModalWindow>
          : <></>
      }
      <ModalWindow
        className={!cookiesAreAccepted ? 'visible' : ''}
      >
        <Cookies />
      </ModalWindow>
      {
        loading
          ? <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
          : <PageWrapper>
            {
              components.map((component, index) => {
                switch (component.type) {
                  case 'Hero':
                    return (
                      <HeroCarousel
                        key={index}
                        listItems={component.content}
                      />
                    );
                  case 'MainEvent':
                    return (
                      <MainEvent
                        key={index}
                        idEvent={component.content.idEvent}
                        format={component.content.format}
                        activityName={component.content.activityName}
                        title={component.content.title}
                        description={component.content.description}
                        startDate={component.content.startDate}
                        callToAction={component.content.callToAction}
                        linkAction={component.content.linkAction}
                        linkBuy={component.content.linkBuy}
                        srcPicture={component.content.urlPicture}
                        books={component.content.books}
                        country={component.content.country}
                      />
                    );
                  case 'ThisMonth':
                    return (
                      <NextMonth
                        key={index}
                        listItems={component.content}
                      />
                    );
                  case 'NewsLetter':
                    return (
                      <NewsLetter
                        key={index}
                      />
                    );
                  case 'Schedule':
                    return (
                      <Schedule
                        key={index}
                      />
                    );
                  case 'No-content':
                    return (
                      <NoContent />
                    );
                  default:
                    return <></>
                }
              })
            }
          </PageWrapper>
      }
    </>
  );
};

export default Landing;
