import styled from '@emotion/styled';
// Componentes 
import { Link } from 'react-router-dom';
// Colores
import { 
    ColorPrimary,
    ColorShadow,
    ColorShadowDarker
} from '../components/common/Pallete.const';

export const LoaderWrapper = styled.div`
    height: calc(100vh - 70px);
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const PageWrapper = styled.div`
    padding-top: 70px;
    @media only screen and (min-width:768px){
        padding-top: 80px;
    }
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
`;
export const MainErrorText = styled.p`
    font-family: 'Shift-Book', serif;
    font-weight: bold;
    text-align: center;
    font-size: 2em ;
    padding: 20vh 15vw 7vh 15vw;
    @media only screen and (min-width: 1024px){
        font-size: 4em ;
    }
`;
export const MainAnchor = styled(Link)`
    width: fit-content;
    border: 1px solid ${ColorPrimary};
    font-family: 'Shift-Book', serif;
    box-shadow: ${ColorShadow} 0 3px 6px;
    font-weight: bold;
    background-color: ${ColorPrimary};
    transition: all ease-in-out 0.2s;
    background-color: ${ColorPrimary};
    color: white;
    text-decoration: none;
    padding: 12px 30px;
    font-size: 17px;
    margin-bottom: 20vh;
    &:hover{
        transform: scale(1.05);
        box-shadow: ${ColorShadowDarker} 0 5px 6px;
    }
    @media only screen and (min-width: 1024px){
        margin-bottom: 20vh;
        font-size: 22px;
        padding: 15px 40px;
    }
`;
