import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types'
// Estilos
import {
    ModalWrapper,
    ModalContainer
} from './ModalWindow.styled'

const ModalWindow = ({ children, className, onClickOut }) => {
    const portalNode = document.createElement('div');

    useEffect(() => {
        document.body.appendChild(portalNode);
        return () => { 
            portalNode.remove();
        }
    }, [portalNode]);

    const handleClickOutside = e => {
        if (portalNode.isEqualNode(e.target.parentNode)) {
            onClickOut();
        }
    }
    return ReactDOM.createPortal(
        <ModalWrapper
            onClick={handleClickOutside}
            className={className}
        >
            <ModalContainer>
                {children}
            </ModalContainer>
        </ModalWrapper >,
        portalNode
    )
}

// Documentacion con PropTypes
ModalWindow.propTypes = {
    className: PropTypes.string,
    onClickOut: PropTypes.func,
}
ModalWindow.defaultProps = {
    onClickOut: () => { },
}

export default ModalWindow