import React from 'react';
import Loader from 'react-loader-spinner'
import { ColorPrimary } from './../common/Pallete.const'
import PropTypes from 'prop-types';
import {
    LoaderWrapper,
} from './Loader.styles';

const Load = ({ height, width, marginTop}) => (
    <LoaderWrapper marginTop={marginTop}>
        <Loader
            type="Grid"
            color={ColorPrimary}
            secondaryColor={ColorPrimary}
            height={height}
            width={width}
        />
    </LoaderWrapper>
);

Load.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    marginTop: PropTypes.number,
}

Load.defaultProps = {
    height: 100,
    width: 100,
    marginTop: 0,
};

export default Load;