import styled from '@emotion/styled';

const FooterWrapper = styled.div`
    background-color: white;
    display: flex;
    flex-direction: column;

    @media(min-width:1024px){
        height: 340px;
    }
`;

const LogoContainerStyled = styled.div`    
    margin-bottom: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 46px;
`;

const LogoStyled = styled.img`
    width: 150px;
    margin-bottom: 23px;
`;

const SocialMediaWrapperStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F9F9F9;
    width: 100%;
    margin-bottom: 46px;
    padding: 30px 0;
`;

const SocialMediaLinkStyles = styled.a`
    cursor: pointer;
    text-decoration: none;
    margin-right: 10px;
`;

const LinkWrapperStyled = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    @media(min-width:1024px){
        margin-bottom: 75px;
    }
`;

const FooterLinksStyled = styled.a`
    text-decoration: none;
    color: black;
    font-size: 14px;
    margin: 15px 5px;
    @media(min-width:1024px){
        margin-right: 39px;
    }
`;

const SocialMediaText = styled.div`
    color: black;
    font-size: 13px;
    margin-right: 20px;
`;

export {
    FooterWrapper,
    LogoContainerStyled,
    LogoStyled,
    SocialMediaWrapperStyled,
    SocialMediaLinkStyles,
    LinkWrapperStyled,
    FooterLinksStyled,
    SocialMediaText,
}