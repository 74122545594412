import styled from "@emotion/styled";
// Componentes generales
import ButtonAction from './../common/ButtonAction.styled'

export const CookiesParagraph = styled.p`
    font-size: 12px;
    @media only screen and (min-width: 768px){
        font-size: 14px;
    }
    @media only screen and (min-width: 1024px){
        font-size: 16px;
    }
`;
export const CookiesAnchor = styled.a`
    font-size: 12px;
    font-weight: bold;
    color: #313030;
    margin: 13px 0;
    display: inline-block;
    @media only screen and (min-width: 768px){
        font-size: 14px;
    }
    @media only screen and (min-width: 1024px){
        font-size: 16px;
        margin: 20px 0;
    }
`;
export const AcceptButton = styled(ButtonAction)`
    @media only screen and (min-width: 500px){
        width: 90%;
        margin: 0 5%;
    }
    @media only screen and (min-width: 768px){
        width: 80%;
        margin: 0 10%;
    }
    @media only screen and (min-width: 1024px){
        width: 60%;
        margin: 0 20%;
    }
`;