// Styled Components
import {
    NoContentWrapper,
    NoContentTitle,
    NoContentSubTitle
} from './NoContent.styled';

const NoContent = () => {
    return (
        <NoContentWrapper>
            <NoContentTitle>¡Próximamente!</NoContentTitle>
            <NoContentSubTitle>Espera Nuestra Cartelera</NoContentSubTitle>
        </NoContentWrapper>
    );
}

export default NoContent;