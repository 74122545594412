import styled from "@emotion/styled";
// Componentes Generales
import FlexRowContainer from '../common/FlexRowContainer.styled';
import ButtonAction from "../common/ButtonAction.styled";
// Colores
import { ColorDarkIcon } from "../common/Pallete.const";

export const EventModalWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-direction: column-reverse;
    position: relative;
    @media only screen and (min-width: 768px){
        flex-direction: row;
        height: 70vh;
        max-height: 450px;
    }
    @media only screen and (min-width: 1024px){
        height: 75vh;
        max-height: 550px;
    }
    @media only screen and (min-width: 1920px){
        height: 80vh;
        max-height: 650px;
    }
`;
export const EventModalCloseButton = styled.button`
    position: absolute;
    width: 20px;
    height: 20px;
    top: -20px;
    right: -10px;
    z-index: 5;
    padding: 0;
    cursor: pointer;
    &::before{
        content: '';
        width: 20px;
        background-color: ${ColorDarkIcon};
        height: 3px;
        display: block;
        border-radius: 3px;
        transform: rotate(45deg);
    }
    &::after{
        content: '';
        position: absolute;
        bottom: 8px;
        width: 20px;
        background-color: ${ColorDarkIcon};
        height: 3px;
        display: block;
        border-radius: 3px;
        transform: rotate(-45deg);
    }
`;
export const EventInfoWrapper = styled.div`
    flex: 5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (min-width: 768px){
        padding-right: 15px;
    }
    @media only screen and (min-width: 1024px){
        padding-right: 25px;
    }
`;
export const EventBooksWrapper = styled.div`
    display: none;
    flex-direction: column;
    justify-content: center;
    @media only screen and (min-width: 768px){
        display: flex;
        flex: 4;
    }
    @media only screen and (min-width: 1920px){
        flex: 3;
    }
`;
export const BookWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
`;
export const BookContainer = styled.img`
    object-fit: contain;
    height: 100%;
    width: auto;
`;
export const EventDefaultImageWrapper = styled.div`
    display: none;
    flex-direction: column;
    justify-content: center;
    @media only screen and (min-width: 768px){
        display: flex;
        flex: 4;
        padding: 0 20px;
    }
    @media only screen and (min-width: 1920px){
        flex: 3;
        padding: 0 40px;
    }
`;
export const EventDefaultImage = styled.img`
    object-fit: contain;
    width: 100%;
    height: auto;
`;
export const EventDetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;
export const EventTitle = styled.h5`
    font-size: 18px;
    font-weight: 800;
    width: 80%;
    padding-bottom: 10px;
    text-transform: none;
    @media only screen and (min-width: 768px){
        font-size: 22px;
        padding-bottom: 15px;
    }
    @media only screen and (min-width: 1024px){
        font-size: 26px;
        padding-bottom: 18px;
    }
    @media only screen and (min-width: 1280px){
        font-size: 2.2em;
    }
    @media only screen and (min-width: 1920px){
        padding-bottom: 25px;
    }
`;
export const EventDescription = styled.p`
    font-size: 14px;
    font-weight: 500;
    @media only screen and (min-width: 1024px){
        font-size: 16px;
    }
    @media only screen and (min-width: 1920px){
        font-size: 20px;
    }
`;
export const EventExtraInfoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    flex-direction: column;
    @media only screen and (min-width: 768px){
        flex-direction: row;
        padding-top: 10px;
    }
    @media only screen and (min-width: 1024px){
        padding-top: 20px;
    }
`;
export const EventPlaceTimeList = styled.ul`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;
`;
export const EventPlaceTimeItem = styled.li`
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 5px;
    @media only screen and (min-width: 768px){
        font-size: 15px;
    }
    @media only screen and (min-width: 1024px){
        font-size: 16px;
    }
    @media only screen and (min-width: 1920px){
        font-size: 18px;
    }
`;
export const CalendarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    a{
        text-decoration: none;
        color: black;
    }
    img{
        width: 20px;
    }
`;
export const SocialMediaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    @media only screen and (min-width: 768px){
        margin-top: 0;
    }
`;
export const SocialMediaContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 6px;
    @media only screen and (min-width: 768px){
        justify-content: space-evenly;
    }
`;
export const RowButtonWrapper = styled(FlexRowContainer)`
    button:first-of-type {
        margin-right: 10px;
    }
    button:last-of-type {
        margin-left: 10px;
    }
`;
export const MainButton = styled(ButtonAction)`
    @media only screen and (min-width: 768px){
        font-size: 16px!important;
        padding: 10px 0!important;
    }
    @media only screen and (min-width: 1024px){
        font-size: 18px!important;
        padding: 12px 0!important;
    }
`;