import { createRef } from 'react';
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom';
// Styled Components
import {
    ThisMonthWrapper,
    ThisMonthWrapperTitle,
    EventsWrapper,
    EventItem,
    ImageWrapper,
    ImageContainer,
    InfoWrapper,
    InfoWrapperFormat,
    InfoWrapperTitle,
    InfoWrapperType,
    DateInfoContainer,
    InfoItem,
    DescriptionContainer,
    ButtonsColumnContainer
} from './ThisMonth.styled'
import ButtonAction from '../common/ButtonAction.styled';
// Componentes Generados
import FloatArrowComponent from '../common/FloatArrow.component';
// Utilidades
import { getDateWithFormat, getTimeWithFormat } from '../../utilities/utils';

const ThisMonth = ({ listItems }) => {
    const history = useHistory();
    // Referencias de componentes
    const eventItemRef = createRef();
    const eventsWrapperRef = createRef();

    const onNextEvent = e => {
        const eventsWrapperNode = eventsWrapperRef.current;
        const widthEventItem = eventItemRef.current.offsetWidth;
        // Hacer scroll
        eventsWrapperNode.scrollLeft = eventsWrapperNode.scrollLeft + widthEventItem;
    }

    const onPrevEvent = e => {
        const eventsWrapperNode = eventsWrapperRef.current;
        const widthEventItem = eventItemRef.current.offsetWidth;
        // Hacer scroll
        eventsWrapperNode.scrollLeft = eventsWrapperNode.scrollLeft - widthEventItem;
    }

    return (
        <ThisMonthWrapper>
            <ThisMonthWrapperTitle>
                Este Mes
            </ThisMonthWrapperTitle>
            <FloatArrowComponent
                onClick={onNextEvent}
                visible={true}
            />
            <FloatArrowComponent
                className="previous"
                onClick={onPrevEvent}
                visible={true}
            />
            <EventsWrapper ref={eventsWrapperRef}>
                {listItems.map((event, index) => {
                    // Obtener fecha
                    const FormatDate = getDateWithFormat(event.startDate, event.country.zonahoraria);
                    const FormatTime = getTimeWithFormat(event.startDate, event.country.zonahoraria);
                    // Funcions "onClick"
                    const onBuy = () => { window.open(event.linkBuy, '_blank').focus(); }
                    const onCall = () => { window.open(event.linkAction, '_blank').focus(); }
                    const onOpenModal = e => {
                        e.preventDefault();
                        if (!e.target.hasAttribute("type"))
                            history.push(`/${event.idEvent}`);
                    }
                    // Imagen del evento
                    const Cover = event.urlPicture;
                    return (
                        <EventItem
                            key={index}
                            ref={eventItemRef}
                            onClick={onOpenModal}
                        >
                            <ImageWrapper>
                                <ImageContainer src={Cover} alt="Libro" />
                            </ImageWrapper>
                            <InfoWrapper>
                                <div>
                                    {
                                        !event.format
                                            ? <InfoWrapperFormat>
                                                {event.format}
                                            </InfoWrapperFormat>
                                            : <></>
                                    }
                                    <InfoWrapperTitle>
                                        {event.title}
                                    </InfoWrapperTitle>
                                    <InfoWrapperType>
                                        {event.activityName}
                                    </InfoWrapperType>
                                    <DateInfoContainer>
                                        <InfoItem><i className="fas fa-calendar-week"></i> {FormatDate}</InfoItem>
                                        <InfoItem><i className="fas fa-clock"></i>{FormatTime} hrs</InfoItem>
                                    </DateInfoContainer>
                                    <DescriptionContainer>
                                        {event.description.split("\n").map((text, index) => (
                                            <span
                                                key={index}
                                            >
                                                {text}
                                                <br />
                                            </span>
                                        ))}
                                    </DescriptionContainer>
                                </div>
                                <ButtonsColumnContainer>
                                    <ButtonAction
                                        type="secondary"
                                        onClick={onBuy}
                                        className=
                                        {
                                            (event.linkBuy === null)
                                                ? 'no-visible'
                                                : ''
                                        }
                                    >
                                        Compra el libro
                                    </ButtonAction>
                                    <ButtonAction type="primary" onClick={onCall}>{event.callToAction}</ButtonAction>
                                </ButtonsColumnContainer>
                            </InfoWrapper>
                        </EventItem>
                    )
                })}
            </EventsWrapper>
        </ThisMonthWrapper>
    );
}

// Documentacion con PropTypes
ThisMonth.propTypes = {
    listItems: PropTypes.array.isRequired,
}

export default ThisMonth;
