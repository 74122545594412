import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    HeaderWrapper,
    LogoWrapper,
    Logo,
    Anchor
} from './Header.styled';
// Assets
import LogoImage from '../../assets/penguin.png'
const Header = () => {
    const kIniPage = process.env.REACT_APP_INI_PAGE_URL
    const kRootPage = process.env.REACT_APP_ROOT_PAGE_URL

    // Funcion para esconder el header al hacer scroll down
    const [show, setShow] = useState(true);
    const controlHeader = () => {
        if (window.scrollY > 80) {
            setShow(false);
        } else {
            setShow(true);
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', controlHeader);
        return () => {
            window.removeEventListener('scroll', controlHeader);
        }
    }, [])

    return (
        <HeaderWrapper className={show ? 'visible' : ''}>
            <Anchor>
                <a href={kRootPage} >
                    Penguin Libros
                </a>
            </Anchor>
            <LogoWrapper href={kIniPage}>
                <Logo
                    src={LogoImage}
                    alt="Penguin logo"
                />
            </LogoWrapper>
            <Anchor>
                <Link to="/streaming/all" >
                    Streaming
                </Link>
            </Anchor>
        </HeaderWrapper>
    );
};

export default Header;