import { useContext, useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { useParams, useLocation } from 'react-router-dom';
// Redes sociales
import { FacebookShareButton, TwitterShareButton } from 'react-share';
// Hooks
import useDetailStreaming from '../../common/hooks/useDetailStreaming';
// Context
import { ModalContext } from '../../context/modal.context';
import { LocationContext } from '../../context/location.context';
// Componentes
import Loader from '../../components/Loader/Loader.component';
import Cookies from '../../components/Cookies/Cookies.component';
import ModalWindow from '../../components/ModalWindow/ModalWindow.component';
import VideoEmbeded from '../../components/VideoEmbeded/VideoEmbeded.component';
// Estilos
import {
    MainAnchor,
    PageWrapper,
    LoaderWrapper,
    MainErrorText,
} from '../Page.styled';
import {
    MainContainer,
    StreamingContainer,
    VideoWrapper,
    OverlayVideo,
    OverlayTitle,
    OverlayCounter,
    Button,
    TitleText,
    DescriptionText,
    AddOnContainer,
    SharingWrapper,
    SharingText,
    SocialMediaWrapper,
    ModalWrapper,
    ModalTitle,
    ModalDescription,
    ModalInput,
    ModalButton
} from './DetailStreaming.styled'
// Assets
import { ReactComponent as Facebook } from './../../assets/icons/Facebook.svg';
import { ReactComponent as Twitter } from './../../assets/icons/Twitter.svg';
// Api
import { getRemoveCode } from '../../common/api-helper';

// Hook personalizado para obtener el codigo de acceso
const useQuery = (keyWord) => {
    const [param, setParam] = useState(null);
    const { search } = useLocation();

    useEffect(() => {
        const query = new URLSearchParams(search);
        setParam(query.get(keyWord));
    }, [setParam, keyWord, search])
    return { param }
}

// Modal para escribir codigo
const ModalCode = ({ setCode }) => {
    const [inputValue, setinputValue] = useState("");

    return (
        <ModalWrapper>
            <ModalTitle>
                Ingresa tu código de acceso
            </ModalTitle>
            <ModalDescription>
                Este código lo puedes verificar en el correo de confirmación del evento
            </ModalDescription>
            <ModalInput
                type="text"
                placeholder="PRH-123456"
                onChange={e => { setinputValue(e.target.value) }} />
            <ModalButton
                type="primary"
                onClick={() => { setCode(inputValue) }}
            >
                Enviar
            </ModalButton>
        </ModalWrapper>
    )
}

// const finalTime = moment().add(5, 'seconds');
// Componente del detalle
const DetailStreaming = () => {
    // Hook para leer url
    const { param } = useQuery("code");
    const uLocation = useLocation();
    // Hook para codigo
    const [inputCode, setInputCode] = useState("");
    // Locacion
    const { location } = useContext(LocationContext);
    const { id } = useParams();
    // Fecha de inicio
    const [timeRemaining, setTimeRemaining] = useState('');
    const [isStreaming, setIsStreaming] = useState(false);
    const currentTime = moment();
    // Hook Personalizado
    const { streaming, loading } = useDetailStreaming(id, location.ip, inputCode);
    // Context de cookies
    const { cookiesAreAccepted } = useContext(ModalContext);
    // Esperar a obtener codigo de la url
    useEffect(() => {
        setInputCode(param);
    }, [setInputCode, param]);
    // Fecha de inicio de evento
    useEffect(() => {
        if (streaming?.type === 'success') {
            const finalTime = moment(streaming.data.event?.dateStart);
            if (currentTime <= finalTime) {
                const timeOut = setTimeout(() => {
                    const diff = finalTime.diff(currentTime, 'seconds');
                    const hours = Math.trunc((diff / 60) / 60);
                    const mins = Math.trunc((diff / 60) - hours * 60);
                    const secs = Math.trunc(diff - (hours * 60 * 60) - (mins * 60));
                    setTimeRemaining(`
                ${hours < 10 ? 0 : ''}${hours}:${mins < 10 ? 0 : ''}${mins}:${secs < 10 ? 0 : ''}${secs}
                `);
                    setIsStreaming(diff < 1);
                }, 1000);
                return () => { clearTimeout(timeOut) }
            } else {
                setIsStreaming(true);
            }
        }
    }, [timeRemaining, currentTime, setIsStreaming, streaming])
    // Al momento de dejar la pagina
    useEffect(() => {
        const onUnload = (event) => {
            getRemoveCode(id, inputCode)
            event.preventDefault();
        }
        window.addEventListener("beforeunload", onUnload);
        return () => { window.removeEventListener("beforeunload", onUnload); }
    }, [id, inputCode])

    // Funciones click
    const onBuy = () => {
        const buyBookLink = streaming.data?.event?.linkBuy;
        window.open(buyBookLink, '_blank').focus();
    }

    return (
        <>
            <ModalWindow
                className={!cookiesAreAccepted ? 'visible' : ''}
            >
                <Cookies />
            </ModalWindow>
            {
                loading
                    ? <LoaderWrapper>
                        <Loader />
                    </LoaderWrapper>
                    : streaming.type === 'error'
                        ? <PageWrapper>
                            <MainErrorText>
                                {`No se encontro el streaming solicitado :(`}
                            </MainErrorText>
                            <MainAnchor to="/">¿Buscamos algo?</MainAnchor>
                        </PageWrapper>
                        : <PageWrapper>
                            <MainContainer>
                                {/* Modal de codigo */}
                                <ModalWindow
                                    className={streaming.type !== 'success' ? 'visible' : ''}
                                >
                                    {
                                        streaming.data === 'ALREADY_USE_CODE'
                                            ? <ModalWrapper>
                                                <ModalTitle>
                                                    Error
                                                </ModalTitle>
                                                <ModalDescription>
                                                    Upppps! Al parecer el código ya ha sido utilizado.
                                                </ModalDescription>
                                                <ModalButton
                                                    type="primary"
                                                    onClick={e => { setInputCode("") }}
                                                >
                                                    Probar otro código
                                                </ModalButton>
                                            </ModalWrapper>
                                            : <ModalCode
                                                setCode={setInputCode}
                                            />
                                    }
                                </ModalWindow>
                                <StreamingContainer>
                                    <VideoWrapper>
                                        <OverlayVideo className={isStreaming ? 'no-visible' : ''}>
                                            <OverlayTitle>
                                                Este evento empieza en:
                                            </OverlayTitle>
                                            <OverlayCounter>
                                                {timeRemaining}
                                            </OverlayCounter>
                                        </OverlayVideo>
                                        {
                                            streaming.type === 'success' && isStreaming
                                                ? <VideoEmbeded
                                                    embededLink={`https://www.youtube.com/embed/${streaming.data?.link}`}
                                                    title={streaming.data?.event.title}
                                                />
                                                : <img src={streaming.data?.event.image} alt="" />
                                        }
                                    </VideoWrapper>
                                    <Button
                                        type="primary"
                                        onClick={onBuy}
                                    >
                                        Compra el libro
                                    </Button>
                                    <TitleText>
                                        {streaming.data?.event.title}
                                    </TitleText>
                                    <DescriptionText>
                                        {streaming.data?.event.description}
                                    </DescriptionText>
                                </StreamingContainer>
                                <AddOnContainer>
                                    {/* <ChatContainer>
                                        <iframe
                                            title="Chat de evento"
                                            width="100%"
                                            height="500px"
                                            src={`https://www.youtube.com/live_chat?v=${streaming.data?.link}&embed_domain=${process.env.REACT_APP_INI_PAGE_URL}`}
                                        />
                                    </ChatContainer> */}
                                    <SharingWrapper>
                                        <SharingText>
                                            Compartir en:
                                        </SharingText>
                                        <SocialMediaWrapper>
                                            <FacebookShareButton
                                                url={`${process.env.REACT_APP_INI_PAGE_URL}${uLocation.pathname}`}
                                                resetButtonStyle={false}
                                            >
                                                <Facebook width="25px" height="25px" />
                                            </FacebookShareButton>
                                            <TwitterShareButton
                                                url={`${process.env.REACT_APP_INI_PAGE_URL}${uLocation.pathname}`}
                                                resetButtonStyle={false}
                                            >
                                                <Twitter width="25px" height="25px" />
                                            </TwitterShareButton>
                                        </SocialMediaWrapper>
                                    </SharingWrapper>
                                </AddOnContainer>
                            </MainContainer>
                        </PageWrapper>
            }
        </>
    )
}

export default DetailStreaming;