import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
// Carousel
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../common/carousel.custom.css";
// Styled Components
import {
  HeroCarouselWrapper,
  HeroSlidesWrapper,
  HeroSlideContainer,
  HeroSlideContainerInfoContainer,
  HeroSlideContainerInfoContainerTitle,
  HeroSlideContainerInfoContainerText,
  HeroSlideContainerBookContainer,
  HeroSlideMainImage,
  HeroSlideContainerFestivalImageWrapper,
  HeroNavigationButton
} from './Hero.styled'
// Componentes Generados
import FloatArrowComponent from '../common/FloatArrow.component';
// Assets
import Background from './../../assets/libraryRes.jpg';
import { ButtonLink } from '../common/ButtonAction.styled';

const HeroCarousel = ({ listItems }) => {
  const history = useHistory();
  // Componente principal de todo el Carousel
  return (
    <HeroCarouselWrapper background={Background}>
      <HeroSlidesWrapper>
        <Carousel
          showThumbs={false}
          dynamicHeight={false}
          autoPlay={true}
          infiniteLoop={true}
          showStatus={false}
          swipeable={false}
          interval={5000}
          renderArrowPrev={(clickHandler) => (
            <FloatArrowComponent
              className="previous"
              onClick={clickHandler}
              visible={true}
            />
          )}
          renderArrowNext={(clickHandler) => (
            <FloatArrowComponent
              onClick={clickHandler}
              visible={true}
            />
          )}
          renderIndicator={(clickHandler, isSelected) => (
            <HeroNavigationButton
              active={isSelected}
              onClick={clickHandler}
            />
          )}
        >
          {
            listItems.map((element, index) => {
              if (element.type === 'event') {
                const showEventModal = e => {
                  e.preventDefault();
                  history.push(`/${element.idEvent}`);
                }
                return (
                  <HeroSlideContainer key={index}>
                    <HeroSlideContainerInfoContainer>
                      <HeroSlideContainerInfoContainerTitle
                        onClick={showEventModal}>
                        <span>
                          {element.title}
                        </span>
                      </HeroSlideContainerInfoContainerTitle>
                      <HeroSlideContainerInfoContainerText>
                        {element.description.split("\n").map((text, index) => (
                          <span
                            key={index}
                          >
                            {text}
                            <br />
                          </span>
                        ))}
                      </HeroSlideContainerInfoContainerText>
                    </HeroSlideContainerInfoContainer>
                    <HeroSlideContainerBookContainer
                      onClick={showEventModal}>
                      <HeroSlideMainImage src={element.srcPicture} alt="Cover Book" />
                    </HeroSlideContainerBookContainer>
                  </HeroSlideContainer>
                )
              }
              // Componente de Festival
              return (
                <HeroSlideContainer id={'slide-' + element.key} key={`carousel_item_${index}}`}>
                  <HeroSlideContainerInfoContainer>
                    <HeroSlideContainerFestivalImageWrapper>
                      <HeroSlideMainImage src={element.srcPicture} alt="Cover Book" />
                    </HeroSlideContainerFestivalImageWrapper>
                    <ButtonLink to={`festival/${element.id}`}>
                      Ver Más
                    </ButtonLink>
                  </HeroSlideContainerInfoContainer>
                </HeroSlideContainer>
              )
            })}
        </Carousel>
      </HeroSlidesWrapper>
    </HeroCarouselWrapper >
  );
}

// Documentacion con proptypes
HeroCarousel.propTypes = {
  listItems: PropTypes.array.isRequired
}

export default HeroCarousel;
