import { useState, useEffect, useContext } from 'react';
import { useAlert } from 'react-alert'
// Api
import { getHome } from '../api-helper';
// Context
import { LocationContext } from '../../context/location.context';

const useHome = () => {
    const [components, setComponents] = useState([]);
    const [loading, setLoading] = useState(false);
    const alert = useAlert()
    const { location } = useContext(LocationContext);

    // Inicializar los componentes del home
    useEffect(() => {
        const loadHome = async () => {
            setLoading(true);
            try {
                if (Object.keys(location).length > 0) {
                    const Home = await getHome(location.country_code);

                    // Dar formato a componentes
                    const FormatComponents = [];
                    // Formatear contenido para el Hero
                    if (Home.hero?.visible) {
                        const EventsHero = Home.hero?.data?.events.map((element) => ({
                            type: 'event',
                            idEvent: element.id_evento,
                            title: element.titulo,
                            description: element.descripcion,
                            srcPicture: element.url_imagen,
                        }));
                        const FestivalsHero = Home.hero?.data?.festivals.map((element, index) => ({
                            type: 'festival',
                            key: index + Home.hero?.data?.events.length,
                            id: Number(element.id_festival),
                            title: element.titulo,
                            srcPicture: element.url_imagen
                        }));
                        FormatComponents.push({
                            type: 'Hero',
                            content: [...FestivalsHero, ...EventsHero]
                        });
                    }
                    // Formatear contenido del Evento principal
                    if (Home.mainEvent?.visible) {
                        const EventMainEvent = {
                            idEvent: Home.mainEvent?.data.id_evento,
                            format: Home.mainEvent?.data.formato_evento,
                            activityName: Home.mainEvent?.data.nombre_actividad,
                            title: Home.mainEvent?.data.titulo,
                            description: Home.mainEvent?.data.descripcion,
                            startDate: Home.mainEvent?.data.fecha_inicio,
                            idEventBrite: Home.mainEvent?.data.id_eventbrite,
                            callToAction: Home.mainEvent?.data.accion,
                            linkAction: Home.mainEvent?.data.link_registro,
                            linkBuy: Home.mainEvent?.data.link_compra,
                            urlPicture: Home.mainEvent?.data.url_imagen,
                            books: Home.mainEvent?.data.books,
                            country: Home.mainEvent?.data.pais
                        };
                        FormatComponents.push({
                            type: 'MainEvent',
                            content: EventMainEvent
                        });
                    }
                    // Formatear contenido para "Este Mes"
                    if (Home.thisMonth?.visible) {
                        const EventsThisMonth = Home.thisMonth.data.map(element => ({
                            idEvent: element.id_evento,
                            format: element.formato_evento,
                            activityName: element.nombre_actividad,
                            title: element.titulo,
                            description: element.descripcion,
                            startDate: element.fecha_inicio,
                            callToAction: element.accion,
                            linkAction: element.link_registro,
                            linkBuy: element.link_compra,
                            urlPicture: element.url_imagen,
                            books: element.books,
                            country: element.pais
                        }));
                        FormatComponents.push({
                            type: 'ThisMonth',
                            content: EventsThisMonth
                        });
                    }
                    // Agregar componente "NewsLetter"
                    if (Home.newsLetter?.visible) {
                        FormatComponents.push({
                            type: 'NewsLetter'
                        });
                    }
                    // Agregar componente "Agenda"
                    if (Home.schedule?.visible) {
                        FormatComponents.push({
                            type: 'Schedule'
                        });
                    }
                    if (FormatComponents.length === 0) {
                        FormatComponents.push({
                            type: 'No-content'
                        });
                    }
                    // Enviar componentes
                    setComponents(FormatComponents);
                }
            } catch (e) {
                console.log('Ocurrió un error al cargar home...', e);
                alert.error('Ocurrió un error, intenta de nuevo más tarde')
            } finally {
                setLoading(false);
            }
        }
        loadHome();
    }, [alert, location])

    return { components, loading };
}

export default useHome;