import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import {
    ColorMainBackground,
    ColorPrimary,
    ColorShadow,
    ColorShadowDarker
} from './Pallete.const';

const ButtonAction = styled.button`
    width: 100%;
    border: 1px solid ${ColorPrimary};
    padding: 11px 0;
    font-family: 'Shift-Book', serif;
    font-size: 16px;
    box-shadow: ${ColorShadow} 0 3px 6px;
    font-weight: bold;
    background-color: ${ColorPrimary};
    transition: all ease-in-out 0.2s;
    &.no-visible{
        visibility: hidden;
    }
    &:hover{
        transform: scale(1.05);
        box-shadow: ${ColorShadowDarker} 0 6px 6px;
    }
    @media only screen and (min-width: 1024px){
        padding: 15px 0;
        font-size: 20px;
    }
    @media only screen and (min-width: 1920px){
        font-size: 24px;
    }
    ${props => props.type === 'primary' ? `
        background-color: ${ColorPrimary};
        color: white;
        ` : ``
    }
    ${props => props.type === 'secondary' ? `
        background-color: ${ColorMainBackground};
        color: ${ColorPrimary};
        ` : ``
    }
`;
export const ButtonLink = styled(Link)`
    text-align: center;
    text-decoration: none;
    width: 100%;
    max-width: 200px;
    border: 1px solid ${ColorPrimary};
    padding: 11px 0;
    font-family: 'Shift-Book', serif;
    font-size: 16px;
    box-shadow: ${ColorShadow} 0 3px 6px;
    font-weight: bold;
    background-color: ${ColorPrimary};
    transition: all ease-in-out 0.2s;
    background-color: ${ColorPrimary};
    color: white;
    &:hover{
        transform: scale(1.05);
        box-shadow: ${ColorShadowDarker} 0 6px 6px;
    }
    @media only screen and (min-width: 1024px){
        padding: 15px 0;
        font-size: 20px;
        max-width: 260px;
    }
    @media only screen and (min-width: 1920px){
        font-size: 24px;
    }
`;

export default ButtonAction;