import React, { useContext } from 'react';
import LogoImage from '../../assets/logoFull.png'
import { ReactComponent as Facebook } from './../../assets/icons/Facebook.svg';
import { ReactComponent as Youtube } from './../../assets/icons/YT.svg';
// Styled Components
import {
    FooterWrapper,
    LogoContainerStyled,
    LogoStyled,
    SocialMediaWrapperStyled,
    SocialMediaLinkStyles,
    LinkWrapperStyled,
    FooterLinksStyled,
    SocialMediaText
} from './Footer.styled'
// Redes sociales
import SocialMedia from './../../utilities/socialmedia.const.json'
// Context
import { LocationContext } from '../../context/location.context';

const Footer = () => {
    // Locacion
    const { location } = useContext(LocationContext);
    // Links de redes sociales
    const SocialMediaObj = SocialMedia[location?.country_code] ?? SocialMedia["DEF"];
    return (
        <FooterWrapper>
            <LinkWrapperStyled>
                <FooterLinksStyled href="https://www.penguinrandomhousegrupoeditorial.com/sobre-nosotros/" target="_blank">
                    Sobre Nosotros
                </FooterLinksStyled>
                <FooterLinksStyled href="https://www.penguinlibros.com/mx/content/9-condiciones-de-uso" target="_blank">
                    Condiciones de Uso
                </FooterLinksStyled>
                <FooterLinksStyled href="https://www.penguinlibros.com/mx/content/10-proteccion-de-datos" target="_blank">
                    Protección de Datos
                </FooterLinksStyled>
                <FooterLinksStyled href="https://www.penguinlibros.com/mx/content/11-politica-de-cookies-" target="_blank">
                    Política de Cookies
                </FooterLinksStyled>
                <FooterLinksStyled href="https://www.penguinlibros.com/mx/contactenos" target="_blank">
                    Contactar con nosotros
                </FooterLinksStyled>
            </LinkWrapperStyled>
            <SocialMediaWrapperStyled>
                <SocialMediaText>Síguenos en las redes</SocialMediaText>
                <SocialMediaLinkStyles
                    href={SocialMediaObj["FB"]}
                    target="_blank"
                >
                    <Facebook />
                </SocialMediaLinkStyles>
                {/* <SocialMediaLinkStyles
                href="https://twitter.com"
                target="_blank"
            >
                <Twitter />
            </SocialMediaLinkStyles>
            <SocialMediaLinkStyles
                href="https://instagram.com"
                target="_blank"
            >
                <Instagram />
            </SocialMediaLinkStyles> */}
                <SocialMediaLinkStyles
                    href={SocialMediaObj["YT"]}
                    target="_blank"
                >
                    <Youtube />
                </SocialMediaLinkStyles>
            </SocialMediaWrapperStyled>
            <LogoContainerStyled>
                <LogoStyled src={LogoImage} alt="Penguin logo" />
            </LogoContainerStyled>
        </FooterWrapper>
    )
}

export default Footer;
