import styled from '@emotion/styled';
import { ColorMainBackground, ColorPrimary } from '../common/Pallete.const';

export const HeaderWrapper = styled.div`
    width: 100%;
    height: 70px;
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    background-color: ${ColorMainBackground};
    z-index: 5;
    transition: all ease-in-out 0.2s;
    transform: translateY(-100%);
    &.visible{
        transform: translateY(0);
    }
    @media only screen and (min-width: 768px){
        height: 80px;
        padding: 0 20px;
    }
    @media only screen and (min-width: 1024px){
        padding: 0 25px;
    }
    @media only screen and (min-width: 1920px){
        padding: 0 40px;
    }
`;
export const LogoWrapper = styled.a`
    height: 80%;
`;
export const Logo = styled.img`
    height: 100%;
`;
export const Anchor = styled.div`
    a{
        transition: all ease-in-out 0.25s;
        text-decoration: none;
        color: black;
        font-family: 'Fort',serif;
        padding: 5px 15px;
        border-bottom: 2px solid transparent;
        font-size: 1rem;
        padding: 3px 5px;
    }
    &:hover a {
        border-bottom: 2px solid ${ColorPrimary};
    }
    @media only screen and (min-width: 768px){
        a{
            padding: 5px 15px;
        }
    }
`;
