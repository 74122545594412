import styled from '@emotion/styled';
// Componentes Generales
import FlexRowContainer from '../common/FlexRowContainer.styled';
// Colores
import {
    ColorPrimary,
    ColorShadowDarker
} from './../common/Pallete.const'

export const MainEventWrapper = styled.section`
    margin-top: 20px;
    display: flex;
    flex-direction: column-reverse;
    @media only screen and (min-width: 768px){
        margin-top: 0;   
        flex-direction: row;
    }
`;
export const InfoContainer = styled.div`
    flex: 1;
    padding: 40px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (min-width: 768px){
        padding: 40px;
    }
    @media only screen and (min-width: 1024px){
        padding: 70px 120px 70px 100px;
    }
`;
export const InfoContainerFormat = styled.h6`
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 800;
    padding-bottom: 5px;
    color: ${ColorPrimary};
    @media only screen and (min-width: 768px){
        font-size: 25px;
    }
    @media only screen and (min-width: 1024px){
        font-size: 30px;
    }
    @media only screen and (min-width: 1920px){
        padding-bottom: 10px;
        font-size: 35px;
    }
`;
export const InfoContainerTitle = styled.button`
    padding: 0;
    letter-spacing: 1px;
    font-family: 'Fort', sans-serif;
    text-align: left;
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 800;
    width: 80%;
    padding-bottom: 20px;
    text-shadow: 0 6px 6px transparent;
    transform: scale(1);
    transition: all 0.2s ease-in-out;
    &:hover{
        transform: scale(1.025);
        text-shadow: 0 6px 6px ${ColorShadowDarker};
    }
    @media only screen and (min-width: 768px){
        font-size: 30px;
    }
    @media only screen and (min-width: 1024px){
        font-size: 50px;
    }
    @media only screen and (min-width: 1920px){
        padding-bottom: 40px;
        font-size: 70px;
    }
`;
export const InfoContainerType = styled.h3`
    font-size: 20px;
    font-weight: 800;
    width: 80%;
    color: ${ColorPrimary};
    padding-bottom: 5px;
    @media only screen and (min-width: 1024px){
        font-size: 25px;
        padding-bottom: 10px;
    }
    @media only screen and (min-width: 1920px){
        font-size: 30px;
        padding-bottom: 15px;
    }
`;
export const InfoContainerText = styled.p`
    font-size: 18px;
    font-weight: 300;
    height: 100%;
    @media only screen and (min-width: 1024px){
        font-size: 22px;
    }
    @media only screen and (min-width: 1920px){
        font-size: 25px;
    }
`;
/* Contenedor de informacion destacada */
export const InfoContainerHighlight = styled(FlexRowContainer)`
    margin-top: 40px;
    margin-bottom: 40px;
    justify-content: space-between;
`;
export const InfoContainerHighlightWrapper = styled.div`
    flex: 1;
    @media only screen and (min-width: 768px){
        margin: 0 5px;
        flex: 1;
    }
`;
export const InfoContainerHighlightTitle = styled.h5`
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    color: ${ColorPrimary};
    @media only screen and (min-width: 1024px){
        font-size: 16px;
    }
`;
export const InfoContainerHighlightText = styled.p`
    margin: 8px 0;
    font-size: 13px;
    @media only screen and (min-width: 1024px){
        font-size: 15px;
    }
    @media only screen and (min-width: 1920px){
        margin: 0.8em 0;
    }
`;
export const RowButtonWrapper = styled(FlexRowContainer)`
    margin-top: 40px;
    button:first-of-type {
        margin-right: 10px;
    }
    button:last-of-type {
        margin-left: 10px;
    }
`;
export const ImageContainer = styled.div`
    flex: 1;
    display: flex;
    padding: 0 20px 10vh 20px;
    ${props => !props.hasbook
        ? 'padding-bottom: 0;'
        : ''}
    justify-content: flex-end;
    position: relative;
    @media only screen and (min-width: 768px){
        padding: 0;
        padding-bottom: 80px;
    }
    @media only screen and (min-width: 1024px){
        padding-bottom: 200px;
    }
    @media only screen and (min-width: 1920px){
        padding-bottom: 250px;
    }
`;
export const EventImage = styled.button`
    padding: 0;
    width: 70vw;
    height: 70vw;
    @media only screen and (min-width: 500px){
        width: 65vw;
        height: 65vw;
    }
    @media only screen and (min-width: 768px){
        width: 47vw;
        height: 47vw;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;
export const BookFloatContainerImage = styled.img`
    position: absolute;
    width: 42vw;
    bottom: 0;
    left: 20px;
    max-height: 280px;
    object-fit: contain;
    @media only screen and (min-width: 768px){
        width: 170px;
        max-height: max-content;
        left: -3vw;
        bottom: 40px;
    }
    @media only screen and (min-width: 1024px){
        width: 250px;   
    }
    @media only screen and (min-width: 1920px){
        width: 300px;   
    }
`;