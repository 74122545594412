// Styled Components
import {
    NewsLetterWrapper,
    BackgroundContainer,
    NewsLetterContainer,
    NewsLetterTitle,
    NewsLetterText,
    EmailContainer,
    EmailContainerButton,
} from './NewsLetter.styled'
// Assets
import Background from './../../assets/libraryRes.jpg';
// Context
// import { LocationContext } from '../../context/location.context';
// Utilidades
// import { validateEmail } from './../../utilities/utils';
// import { subscribeNewsLetter } from './../../common/api-helper';

const NewsLetter = () => {
    /*
    const [message, setMessage] = useState({
        class: '',
        title: ''
    })
    // Hook para guardar el email
    const [email, setEmail] = useState('');
    const { location } = useContext(LocationContext);

    const keyUpEmail = e => {
        if (e.code === 'Enter') {
            window.open('', '_blank');
        } else {
            setEmail(e.target.value)
        }
    };
    */

    const sendEmail = async e => {
        window.open(process.env.REACT_APP_NEWSLETTER, "_blank");

        // if (message.class === 'loading') {
        //     return;
        // }

        // if (validateEmail(email)) {
        //     setMessage({
        //         class: 'loading',
        //         title: ''
        //     });
        //     try {
        //         const Response = await subscribeNewsLetter(email, location.country_code);
        //         if (Response.type === 'success') {
        //             setMessage({
        //                 class: 'success',
        //                 title: 'Email registrado exitosamente'
        //             });
        //         } else {
        //             setMessage({
        //                 class: 'warning',
        //                 title: Response.message ?? 'Ocurrio un error inesperado, intentelo de nuevo más tarde'
        //             });
        //         }
        //     } catch (e) {
        //         setMessage({
        //             class: 'error',
        //             title: 'Ocurrio un error inesperado, intentelo de nuevo más tarde'
        //         });
        //     }
        // } else {
        //     setMessage({
        //         class: 'error',
        //         title: 'Verifique su email'
        //     });
        // }
    }

    return (
        <NewsLetterWrapper>
            <BackgroundContainer background={Background} />
            <NewsLetterContainer>
                <div>
                    <NewsLetterTitle>
                        Newsletter
                    </NewsLetterTitle>
                    <NewsLetterText>
                        Suscríbete a nuestro newsletter y mantente al día de nuestros últimos eventos.
                    </NewsLetterText>
                </div>
                <EmailContainer>
                    {/* <EmailContainerInput
                        type="email"
                        placeholder="Email"
                        onKeyUp={keyUpEmail}
                    /> */}
                    <EmailContainerButton type="primary" onClick={sendEmail}>¡Regístrate!</EmailContainerButton>
                    {/* <EmailContainerAlert className={message.class}>{message.title}</EmailContainerAlert> */}
                </EmailContainer>
            </NewsLetterContainer>
        </NewsLetterWrapper>
    );
};

export default NewsLetter;
