const axios = require('axios').default;

// Metodo Post para enviar datos usando url y formData
const PostRequest = async (url = '', data = new FormData()) =>
(axios.post(url, data)
    .then(res => (res.data))
    .catch(error => ({ error: error }))
);

const GetRequest = async (url = '') =>
(axios.get(url)
    .then(res => (res.data))
    .catch(error => ({ error: error }))
);

//Metodo para tratar de parsear un String a JSON
const TryParseJSON = (string = '', printError = false) => {
    let parse;
    try {
        parse = JSON.parse(string);
    } catch (e) {
        if (printError) {
            console.log(e);
            console.log(string);
        }
        return false;
    }
    return parse;
}

// Metodo para devoler un objeto FormData, dado un json
// para ser enviado a traves de una solicitud post
const JsonToFormdata = (jsonObj = {}, action = '') => {
    let dataAux = new FormData();
    dataAux.append("action", action);
    Object.keys(jsonObj).forEach(key => {
        dataAux.append(key, jsonObj[key]);
    });
    return dataAux;
}

export { GetRequest, PostRequest, TryParseJSON, JsonToFormdata };