import styled from '@emotion/styled';

const CustomScrollContainer = styled.div`
    &::-webkit-scrollbar {
        height: 0px;
        display: none;
        scrollbar-color: transparent;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
        background: rgb(199, 199, 199);
        border-radius: 5px;
    }
`;

export default CustomScrollContainer;