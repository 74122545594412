import moment from 'moment-timezone';
import { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
// Api
import { getStreamingsBetweenDates } from '../api-helper';

const useAllStreaming = (countryCode) => {
    // Eventos
    const [lastEvents, setLastEvents] = useState([]);
    const [nextEvents, setNextEvents] = useState([]);
    // Carga
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const alert = useAlert();

    // Informacion del Streaming
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            setError(false);
            try {
                if (countryCode) {
                    // Fechas para eventos
                    const today = moment().format();
                    const endNextEvents = moment().add(1, 'year').format();
                    const beginLastEvents = moment().subtract(30, 'days').format();
                    // Buscar anteriores eventos
                    const reqNext = await getStreamingsBetweenDates(countryCode, today, endNextEvents);
                    const reqLast = await getStreamingsBetweenDates(countryCode, beginLastEvents, today);
                    // Cargar eventos
                    setLastEvents([...reqLast.data]);
                    setNextEvents([...reqNext.data]);
                }
            } catch (e) {
                console.log('Ocurrió un error al cargar streaming...', e);
                alert.error('Ocurrió un error, intenta de nuevo más tarde');
                setError(true);
            } finally {
                setLoading(false);
            }
        }
        loadData();
    }, [alert, countryCode, setLastEvents, setNextEvents, setError])

    return { loading, lastEvents, nextEvents, error }
}

export default useAllStreaming;