import styled from '@emotion/styled';

import FullScreenContainer from '../../components/common/FullScreenContainer.styled';

export const MainImagaWrapper = styled(FullScreenContainer)`
    background-image: url(${props => props.background});
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    height: 250px;
    padding: 20px 0;
    @media only screen and (min-width: 768px){
        height: 300px;
        padding: 30px 0;
    }
    @media only screen and (min-width: 1024px){
        height: 400px;
        padding: 40px 0;
    }
    @media only screen and (min-width: 1920px){
        height: 500px;
        padding: 50px 0;
    }
`;
export const MainImage = styled.img`
    height: 100%;
`;