import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// Paginas
import Landing from './pages/Landing/Landing.page';
import Festival from './pages/Festival/Festival.page';
import AllStreaming from './pages/AllStreaming/AllStreaming.page'
import DetailStreaming from './pages/DetailStreaming/DetailStreaming.page';
// Componentes
import Header from './components/Header/Header.component';
import Footer from './components/Footer/Footer.component';
// Context
import LocationProvider from './context/location.context'
import ModalProvider from './context/modal.context'

function App() {
  return (
    <LocationProvider>
      <ModalProvider>
        <Router>
          <Header />
          <Switch>
            <Route exact path="/:idEvent?">
              <Landing />
            </Route>
            <Route exact path="/festival/:id">
              <Festival />
            </Route>
            <Route exact path="/streaming/all">
              <AllStreaming />
            </Route>
            <Route exact path="/streaming/detail/:id">
              <DetailStreaming />
            </Route>
          </Switch>
          <Footer />
        </Router>
      </ModalProvider>
    </LocationProvider>
  );
}

export default App;
