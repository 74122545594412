import styled from '@emotion/styled';

export const ModalWrapper = styled.div`
    @keyframes fadein {
        from {opacity:0;}
        to {opacity:1;}
    }
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 10;
    display: none;
    justify-content: center;
    flex-direction: column;
    padding: 0 25px;
    &.visible{
        display: flex;
        animation: fadein 0.15s ease-out;
    }
    @media only screen and (min-width: 500px){
        padding: 0 40px;
    }
    @media only screen and (min-width: 768px){
        padding: 0 20vw;
        &.lg{
            padding: 0 10vw;
        }
    }
    @media only screen and (min-width: 1024px){
        padding: 0 30vw;
        &.lg{
            padding: 0 12vw;
        }
    }
    @media only screen and (min-width: 1920px){
        padding: 0 35vw;
        &.lg{
            padding: 0 20vw;
        }
    }
`;
export const ModalContainer = styled.div`
    background-color: white;
    border-radius: 10px;
    padding: 30px 20px;
    @media only screen and (min-width: 1024px){
        padding: 40px 30px;
    }
`;