import styled from "@emotion/styled";
// Componentes Genericos
import ButtonAction from "../common/ButtonAction.styled";
// Colores
import {
    ColorShadow
} from './../common/Pallete.const'

export const EventContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid black;
`;
export const BasicWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 35px 0;
    align-items: flex-start;
    @media only screen and (min-width: 768px){
        padding: 15px 20px;
        flex-direction: row;
        align-items: center;
    }
`;
export const VisualsWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: space-evenly;
    @media only screen and (min-width: 768px){
        flex-direction: row;
        align-items: center;
        width: 270px;
    }
    @media only screen and (min-width: 1024px){
        width: 325px;
    }
    @media only screen and (min-width: 1920px){
        width: 490px;
    }
`;
export const OnlyImageWrapper = styled(VisualsWrapper)`
    @media only screen and (min-width: 768px){
        width: 180px;
    }
    @media only screen and (min-width: 1024px){
        width: 200px;
    }
    @media only screen and (min-width: 1920px){
        width: 280px;
    }
`;
export const EventImage = styled.img`
    cursor: pointer;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    margin: 18px 0;
    box-shadow: 0 6px 6px transparent;
    transform: scale(1);
    transition: all 0.2s ease-in-out;
    &:hover{
        transform: scale(1.01);
        box-shadow: 0 2px 6px ${ColorShadow};
    }
    @media only screen and (min-width: 768px){
        width: 100px;
        height: 100px;
        margin-right: 10px;
    }
    @media only screen and (min-width: 1920px){
        width: 180px;
        height: 180px;
        margin-right: 20px;
    }
`;
export const DateContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media only screen and (min-width: 768px){
        margin-right: 10px;
        padding: 15px;
    }
    @media only screen and (min-width: 1024px){
        margin-right: 20px;
        padding: 30px;
    }
    @media only screen and (min-width: 1920px){
        margin-right: 50px;
    }
`;
export const DateSubTitle = styled.p`
    font-size: 18px;
    font-family: 'Fort', sans-serif;
    font-weight: bold;
    display: none;
    text-transform: capitalize;
    text-align: center;
    ${props => props.active
        ? `display: block;`
        : ''};
    &.bottom-child{
        text-align: left;
        margin-top: 15px;
    }
    @media only screen and (min-width: 768px){
        font-size: 22px;
        margin-top: 0;
    }
    @media only screen and (min-width: 1024px){
        font-size: 25px;
    }
    @media only screen and (min-width: 1920px){
        font-size: 30px;
    }
`;
export const DateTitle = styled.h6`
    font-size: 35px;
    font-family: 'Fort', sans-serif;
    font-weight: bold;
    text-align: center;
    @media only screen and (min-width: 768px){
        font-size: 45px;
    }
    @media only screen and (min-width: 1024px){
        font-size: 54px;
    }
    @media only screen and (min-width: 1920px){
        font-size: 85px;
    }
`;
export const DateText = styled.span`
    font-size: 14px;
    width: 100%;
    font-family: 'Fort', sans-serif;
    font-weight: bold;
    text-align: center;
    display: block;
    @media only screen and (min-width: 768px){
        font-size: 17px;
    }
    @media only screen and (min-width: 1024px){
        font-size: 20px;
    }
    @media only screen and (min-width: 1920px){
        font-size: 33px;
    }
`;
export const InfoContainer = styled.div`    
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: 768px){
        margin-right: 20px;
        flex: 2;
        &.large{
            flex: 4;
        }
    }
    @media only screen and (min-width: 1024px){
        margin-right: 40px;
    }
`;
export const InfoTitle = styled.button`
    padding: 0;
    font-size: 14px;
    font-family: 'Fort', sans-serif;
    font-weight: bold;
    text-align: left;
    margin-bottom: 10px;
    text-shadow: 0 6px 6px transparent;
    transform: scale(1);
    transition: all 0.2s ease-in-out;
    &:hover{
        transform: scale(1.01);
        text-shadow: 0 1px 3px ${ColorShadow};
    }
    @media only screen and (min-width: 768px){
        font-size: 17px;
        margin-top: 20px;
        &.first{
            margin-bottom: 0px;
        }
    }
    @media only screen and (min-width: 1024px){
        font-size: 20px;
        margin-bottom: 20px;
    }
    @media only screen and (min-width: 1920px){
        font-size: 33px;
    }
`;
export const InfoParagraph = styled.p`
    font-size: 16px;
    font-family: 'Fort', sans-serif;
    font-weight: 400;
    text-align: left;
    margin-bottom: 30px;
    @media only screen and (min-width: 768px){
        margin-bottom: 0;
    }
    @media only screen and (min-width: 1024px){
        font-size: 18px;
    }
    @media only screen and (min-width: 1920px){
        font-size: 20px;
    }
`;
export const BooksWrapper = styled.div`
    padding: 0 325px;
    display: none;
    @media only screen and (min-width: 1024px){
        display: block;
    }
    @media only screen and (min-width: 1920px){
        padding: 0 490px;
    }
`;
export const BooksContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
`;
export const BookImage = styled.img`
    height: 0;
    transition: all ease-in-out 0.25s;
    object-fit: contain;
    padding: 15px 0 30px 0;
    height: 180px;
    @media only screen and (min-width: 1270px){
        height: 210px;
    }
    @media only screen and (min-width: 1920px){
        height: 250px;
        padding-bottom:  50px;
    }
`;
export const MainButton = styled(ButtonAction)`
    width: 50%;
    &.first{
        margin-bottom: 15px;
    }
    &.no-visible{
        visibility: hidden;
    }
    @media only screen and (min-width: 768px){
        width: 140px;
        &.first{
            margin-bottom: 0;
            margin-right: 15px;
        }
    }
    @media only screen and (min-width: 1024px){
        width: 180px;
        &.first{
            margin-right: 25px;
        }
    }
    @media only screen and (min-width: 1920px){
        width: 240px;
    }
`;
export const ArrowContainer = styled.button`
    color: black;
    font-size: 22px;
    margin-left: 5%;
    display: none;
    transform: rotateY(0);
    transition: all ease-in-out 0.25s; 
    @media only screen and (min-width: 1024px){
        display: block;
    }
    @media only screen and (min-width: 1920px){
        font-size: 28px;
        margin-left: 7%;
    }
    &.active{
        transform: rotateX(180deg);
    }
    ${props => !props.hasBooks ? 'visibility: hidden;' : ''}
`;