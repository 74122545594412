   
import styled from '@emotion/styled';

export const LoaderWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: ${props => props.marginTop}px;
`;