import styled from '@emotion/styled';
// Utilidades
import {
    ColorPrimary,
    ColorShadow,
    ColorShadowDarker
} from '../../components/common/Pallete.const'

export const SectionTitle = styled.h5`
    width: 100%;
    text-align: center;
    font-weight: 800;
    font-size: 25px;
    margin: 20px 0;
    @media only screen and (min-width: 768px){
        font-size: 30px;
        margin-bottom: 35px; 
    }
    @media only screen and (min-width: 1024px){
        font-size: 50px;
    }
`;
export const MainEventsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px;
    gap: 20px;
    margin-bottom: 20px;
    @media only screen and (min-width: 500px){
        padding: 0 40px;
        margin-bottom: 40px;
    }
    @media only screen and (min-width: 768px){
        flex-direction: row;
        gap: 2.5vw;
        padding: 0 5vw;
        margin-bottom: 60px;
    }
`;
export const MainEventContainer = styled.div`
    width: 100%;
    height: 250px;
    border-radius: 20px;
    background: url(${props => props.background});
    background-size: contain;
    @media only screen and (min-width: 500px){
        height: 300px;
    }
    @media only screen and (min-width: 768px){
        height: auto;
    }
`;
export const MainEventDetailsWrapper = styled.div`
    background-color: rgba(26, 26, 26, 0.35);
    width: 100%;
    height: 100%;
    padding: 30% 0 10% 0;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;
export const MainEventDate = styled.h6`
    color: white;
    font-weight: 400;
    text-align: center;
    font-size: 15px;
    @media only screen and (min-width: 768px){
        font-size: 17px;
    }
    @media only screen and (min-width: 1024px){
        font-size: 20px;
    }
`;
export const MainEventTitle = styled.h4`
    text-align: center;
    font-family: 'Fort', serif;
    font-size: 30px;
    color: white;
    font-weight: bold;
    @media only screen and (min-width: 768px){
        font-size: 34px;
    }
    @media only screen and (min-width: 1024px){
        font-size: 38px;
    }
`;
const ActionButton = styled.button`
    border: 1px solid ${ColorPrimary};
    padding: 3px 0;
    font-family: 'Shift-Book', serif;
    font-size: 8px;
    box-shadow: ${ColorShadow} 0 3px 6px;
    font-weight: bold;
    color: white; 
    background-color: ${ColorPrimary};
    transition: all ease-in-out 0.2s;
    &:hover{
        transform: scale(1.05);
        box-shadow: ${ColorShadowDarker} 0 6px 6px;
    }
    @media only screen and (min-width: 768px){
        padding: 6px 0;
        font-size: 12px;
    }
    @media only screen and (min-width: 1024px){
        font-size: 18px;
    }
`;
export const MainEventButton = styled(ActionButton)`
    width: 30%;
    margin-left: 50%;
    margin-top: 30px;
    @media only screen and (min-width: 768px){
        margin-left: calc(70% / 2);
    }
    @media only screen and (min-width: 1024px){
        margin-top: 50px;
    }
`;
export const NextEventsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    @media only screen and (min-width: 768px){
        gap: 2.5vw;
    }
`;
export const NextEventContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
    height: 100%;
    @media only screen and (min-width: 768px){
        height: calc(50% - 1.25vw);
        gap: 2.5vw;
    }
`;
export const NextEventImageWrapper = styled.div`
    flex: 1;
    min-height: 120px;
    @media only screen and (min-width: 500px){
        height: 160px;
    }
    @media only screen and (min-width: 768px){
        height: 100%;
    }
`;
export const NextEventImage = styled.img`
    height: 100%;
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
`;
export const NextEventInfo = styled.div`
    flex: 1;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
export const NextEventDate = styled.h6`
    font-size: 13px;
    @media only screen and (min-width: 768px){
        font-size: 15px;
    }
`;
export const NextEventTitle = styled.h4`
    font-family: 'Fort', serif;
    font-size: 15px;
    font-weight: bold;
    @media only screen and (min-width: 768px){
        font-size: 18px;
    }
    @media only screen and (min-width: 1024px){
        font-size: 21px;
    }
`;
export const NextEventDescription = styled.p`
    font-size: 11px;
    margin: 5px 0;
    @media only screen and (min-width: 768px){
        font-size: 14px;
    }
    @media only screen and (min-width: 1024px){
        margin: 20px 0;
        font-size: 15px;
    }
`;
export const NextEventButton = styled(ActionButton)`
    width: 50%;
`;
export const LastMonthWrapper = styled.div`
    width: 100%;
    padding: 0 20px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    margin-bottom: 40px;
    @media only screen and (min-width: 500px) {
        padding: 0 40px;
        grid-gap: 40px;
    }
    @media only screen and (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        padding: 0 5vw;
        grid-gap: 2.5vw;
        margin-bottom: 100px;
    }
`;
export const LastMonthItemWrapper = styled.div`
    height: 250px;
    border-radius: 20px;
    color: white;
    background: url(${props => props.background});
    background-position: center;
    background-size: contain;
    filter: grayscale(100%);
    position: relative;
    @media only screen and (min-width: 768px) {
        height: 300px;
    }
    @media only screen and (min-width: 1024px) {
        height: 350px;
    }
    @media only screen and (min-width: 1920px) {
        height: 450px;
    }
`;
export const LastMonthItemContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: rgba(19, 19, 19, 0.65);
    padding: 0 20px;
    cursor: pointer;
    svg{
        width: 10%;
        height: auto;
        transition: all 0.25s ease-in-out;
        transform: scale(1.0);
    }
    &:hover{
        svg{
            transform: scale(1.2);
        }
    }
    @media only screen and (min-width: 768px) {
        padding: 0 5%;
        svg{
            width: 15%;
        }
    }
`;

export const LastMonthTitle = styled.h4`
    font-size: 22px;
    font-family: 'Fort', sans-serif;
    margin-top: 10px;
    color: white;
    text-align: left;
    width: 100%;
    @media only screen and (min-width: 768px) {
        font-size: 28px;
        margin-top: 20px;
    }
    @media only screen and (min-width: 1024px) {
        font-size: 35px;
    }
    @media only screen and (min-width: 1920px) {
        font-size: 40px;
        margin-top: 30px;
    }
`;
export const LastMonthDesc = styled.p`
    font-weight: 300;
    font-size: 0.75em;
    @media only screen and (min-width: 768px) {
        font-size: 0.8em;
        margin-top: 10px;
    }
    @media only screen and (min-width: 1024px) {
        font-size: 0.9em;
    }
    @media only screen and (min-width: 1920px) {
        font-size: 1em;
    }
`;