import { useContext } from 'react'
// Context
import { ModalContext } from '../../context/modal.context'
// Estilos
import {
    CookiesParagraph,
    CookiesAnchor,
    AcceptButton
} from './Cookies.styled'

const Cookies = () => {
    const {setCookiesAreAccepted} = useContext(ModalContext);

    const acceptCookies = e => {
        e.preventDefault();
        localStorage.setItem('cookiesAreAccepted', true);
        setCookiesAreAccepted(true)
    }

    return (
        <>
            <CookiesParagraph>
                Utilizamos cookies propias y de terceros para fines analíticos y para mostrarte publicidad personalizada en base a un perfil elaborado a partir de tus hábitos de navegación (por ejemplo, páginas visitadas). Te recomendamos aceptar su uso para aprovechar plenamente la navegación.
            </CookiesParagraph>
            <CookiesAnchor href={process.env.REACT_APP_COOKIES} target="_blank">
                Más informacion
            </CookiesAnchor>
            <AcceptButton
                type="primary"
                onClick={acceptCookies}
            >
                Aceptar
            </AcceptButton>
        </>
    )
}

export default Cookies;