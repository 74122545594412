import styled from '@emotion/styled';
// Componentes Generales
import FlexRowContainer from '../common/FlexRowContainer.styled';
import ButtonAction from '../common/ButtonAction.styled';
// Colores
import {
    ColorMainBackground,
    ColorWarning,
    ColorDefeat,
    ColorSuccess,
    ColorShadow
} from '../common/Pallete.const';

export const NewsLetterWrapper = styled.div`
    margin: 40px 0;
    width: 100%;
    height: 35vh;
    min-height: 255px;
    @media only screen and (min-width: 768px){
        height: 50vh;
        min-height: 430px;
        max-height: 500px;
        margin-bottom: 0;
    }
    @media only screen and (min-width: 1024px){
        height: 60vh;
        min-height: 480px;
        margin: 50px 0;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: relative;
`;

export const BackgroundContainer = styled.div`
    height: 50%;
    @media only screen and (min-width: 1024px){
        height: 100%;
    }
    top: 0;
    width: 100%;
    content: '';
    background-image: url(${props => props.background});
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
    position: absolute;
`

export const NewsLetterContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: space-evenly;
    width: calc(100% - 40px);
    z-index: 1;
    background-color: ${ColorMainBackground};
    border-radius: 5px;
    box-shadow: 0px 0px 20px ${ColorShadow};
    padding: 30px 20px;
    @media only screen and (min-width: 768px){
        width: 70%;
        height: 65%;
        padding: 20px 50px;
    }
    @media only screen and (min-width: 1024px){
        width: 550px;
        height: 70%;
        padding: 50px;
    }
    @media only screen and (min-width: 1920px){
        width: 800px;
    }
`;

export const NewsLetterTitle = styled.h5`
    font-family: 'Shift-Book';
    text-align: center;
    font-size: 22px;
    @media only screen and (min-width: 768px){
        font-size: 30px;
    }
    @media only screen and (min-width: 1024px){
        font-size: 40px;
    }
    @media only screen and (min-width: 1920px){
        font-size: 60px;
    }
    font-weight: normal;
`;

export const NewsLetterText = styled.p`
    text-align: center;
    font-weight: normal;
    font-size: 15px;
    font-weight: 300;
    margin: 20px 0;
    font-family: 'Fort', sans-serif;
    font-weight: lighter;
    @media only screen and (min-width: 1024px){
        font-size: 18px;
    }
    @media only screen and (min-width: 1920px){
        font-size: 20px;
    }
`;

export const EmailContainer = styled(FlexRowContainer)`
    position: relative;
    padding-bottom: 30px;
`;

export const EmailContainerInput = styled.input`
    font-family: 'Fort', sans-serif;
    outline: none;
    flex: 4;
    margin-right: 15px;
    padding: 5px 15px;
    width: calc(100% - 20px);
    font-size: 12px;
    &.footer {
        width: 254px;
    }
    @media only screen and (min-width: 1024px){
        font-size: 15px;
    }
    @media only screen and (min-width: 1920px){
        font-size: 18px;
        padding: 15px 15px;
    }
`;

export const EmailContainerButton = styled(ButtonAction)`
    flex: 1;
    font-size: 1em;
    padding: 10px 15px;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 12px;
    &.footer {
        width: 112px;
    }
    @media only screen and (min-width: 1024px){
        font-size: 15px;
        padding: 10px 25px;
    }
    @media only screen and (min-width: 1920px){
        font-size: 18px;
    }
`;

export const EmailContainerAlert = styled.p`
    position: absolute;
    font-size: 0.8em;
    bottom: 5px;
    display: none;
    &.error {
        color: ${ColorDefeat};
        display: block;
    }

    &.warning {
        color: ${ColorWarning};
        display: block;
    }

    &.success {
        color: ${ColorSuccess};
        display: block;
    }

    &.footer {
        position: static;
        display: block;
        height: 16px;
    }
`;