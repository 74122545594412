import { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
// Api
import { getStreamingId } from '../api-helper';

const useDetailStreaming = (idStreaming, ipAddress, privateCode) => {
    const [streaming, setStreaming] = useState({});
    const [loading, setLoading] = useState(true);
    const alert = useAlert();

    // Informacion del Streaming
    useEffect(() => {
        const loadStreaming = async () => {
            setLoading(true);
            if (ipAddress)
                try {
                    // Cargar datos del Streaming
                    const req = await getStreamingId(idStreaming, ipAddress, privateCode);
                    if(!req.hasOwnProperty("error")){
                        setStreaming({
                            ...req
                        });
                    }else{
                        setStreaming({
                            type: "error"
                        });
                    }
                } catch (e) {
                    console.log('Ocurrió un error al cargar streaming...', e);
                    alert.error('Ocurrió un error, intenta de nuevo más tarde')
                    setStreaming({
                        type: "error"
                    });
                } finally {
                    setLoading(false);
                }
        }
        loadStreaming();
    }, [alert, setStreaming, idStreaming, ipAddress, privateCode])

    return { streaming, loading }
}

export default useDetailStreaming;