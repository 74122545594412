import styled from '@emotion/styled';
import ButtonAction from '../../components/common/ButtonAction.styled';

export const MainContainer = styled.div`
    width: 100%;
    display: flex;
    padding: 5vh 5vw;
    gap: 2.5vw;
    margin-bottom: 5vh;
    flex-direction: column;
    @media only screen and (min-width: 768px){
        flex-direction: row;
    }
    @media only screen and (min-width: 1920px){
        padding: 5vh 7.5vw;
        gap: 4vw;
    }
`;
export const StreamingContainer = styled.div`
    flex:3;
`;
export const VideoWrapper = styled.div`
    width: 100%;
    height: 450px;
    background-color: #adadad;
    position: relative;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
    }
    @media only screen and (min-width: 1024px){
        height: 500px;
    }
    @media only screen and (min-width: 1920px){
        height: 650px;
    }
`;
export const Button = styled(ButtonAction)`
    margin-top: 20px;
    width: 75%;
    @media only screen and (min-width: 768px){
        margin-top: 30px;
        width: 50%;
    }
    @media only screen and (min-width: 1024px){
        width: 40%;
    }
    @media only screen and (min-width: 1920px){
        margin-top: 40px;
        width: 30%;
    }
`;
export const TitleText = styled.h1`
    margin-top: 15px;
    font-size: 25px;
    @media only screen and (min-width: 768px){
        font-size: 30px;
    }
    @media only screen and (min-width: 1024px){
        font-size: 35px;
    }
    @media only screen and (min-width: 1920px){
        margin-top: 20px;
        font-size: 40px;
    }
`;
export const DescriptionText = styled.p`
    margin-top: 20px;
    margin-bottom: 30px;
    @media only screen and (min-width: 1024px){
        font-size: 1.1em;
    }
    @media only screen and (min-width: 1920px){
        font-size: 1.25em;
    }
`;
export const OverlayVideo = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    color: white!important;
    text-align: center;
    padding: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    z-index: 10;
    &.no-visible{
        display: none;
    }
`;
export const OverlayTitle = styled.h5`
    font-size: 40px;
    text-transform: uppercase;
`;
export const OverlayCounter = styled.h6`
    font-size: 80px;
`;
export const AddOnContainer = styled.div`
    flex: 1;
`;
export const ChatContainer = styled.div`
    height: 500px;
    background-color: #d4d4d4;
    display: none;
    @media only screen and (min-width: 768px){
        display: block;
    }
`;
export const SharingWrapper = styled.div`
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    align-items: center;
`;
export const SharingText = styled.p`
    font-weight: 200;
    font-size: 0.85em;
    @media only screen and (min-width: 1024px){
        font-size: 1em;
    }
    @media only screen and (min-width: 1920px){
        font-size: 1.1em;
    }
`;
export const SocialMediaWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 7.5px;
    svg{
        width: 30px;
        height: 30px;
        cursor: pointer;
    }
`;
/* -------------------------------
    Modal para codigo de acceso
------------------------------- */
export const ModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.5% 5%;
`;
export const ModalTitle = styled.h6`
    font-family: 'Shift-book', serif;
    text-align: center;
    font-weight: normal;
    font-size: 25px;
    @media only screen and (min-width: 768px){
        font-size: 35px;
    }
    @media only screen and (min-width: 1024px){
        font-size: 45px;
    }
    @media only screen and (min-width: 1920px){
        font-size: 55px;
    }
`;
export const ModalDescription = styled.p`
    text-align: center;
    font-size: 12px;
    margin-top: 30px;
    @media only screen and (min-width: 768px){
        font-size: 14px;
    }
    @media only screen and (min-width: 1024px){
        font-size: 16px;
    }
`;
export const ModalInput = styled.input`
    font-family: 'Fort', sans-serif;
    outline: none;
    padding: 8px 15px;
    width: 80%;
    font-size: 12px;
    margin-top: 20px;
    @media only screen and (min-width: 1024px){
        font-size: 15px;
    }
    @media only screen and (min-width: 1920px){
        font-size: 18px;
        padding: 15px 15px;
    }
`;
export const ModalButton = styled(ButtonAction)`
    font-size: 1em;
    padding: 10px 15px;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 12px;
    width: 40%;
    margin-top: 20px;
    @media only screen and (min-width: 1024px){
        font-size: 15px;
        padding: 10px 25px;
    }
    @media only screen and (min-width: 1920px){
        font-size: 18px;
    }
`;