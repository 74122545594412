import { useState } from "react";
import { useHistory } from "react-router-dom";
import moment from 'moment-timezone';
import 'moment/locale/es';
// Animacion al expandir
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
// Estilos
import {
    EventContainer,
    BasicWrapper,
    VisualsWrapper,
    EventImage,
    DateTitle,
    DateText,
    DateContainer,
    DateSubTitle,
    InfoContainer,
    InfoTitle,
    InfoParagraph,
    MainButton,
    BooksContainer,
    BookImage,
    ArrowContainer,
} from './EventInfo.styled'

const EventInfo = ({
    idEvent,
    startDate,
    timeZone,
    image,
    title,
    description,
    isSubtitleActive,
    callToAction,
    register,
    books
}) => {
    const history = useHistory();
    const [showBooks, setShowBooks] = useState(false);
    // Formatear numeros en fechas
    const originalDate = moment.tz(startDate, timeZone);
    // Obtener zona horaria del navegador
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const eventDate = originalDate.clone().tz(currentTimeZone);
    moment.locale('es')
    // Obtener fecha con formato
    const EvDate = eventDate.format("DD");
    const EvHour = eventDate.format("kk");
    const EvMins = eventDate.format("mm");
    // Funciones "onClick"
    const onCall = () => { window.open(register, '_blank').focus(); }
    const onOpenModal = e => {
        e.preventDefault();
        history.push(`/${idEvent}`);
    }
    // Funcion para mostrar libros
    const onShowBooks = e => { setShowBooks(!showBooks) }
    return (
        <EventContainer>
            <BasicWrapper>
                <VisualsWrapper>
                    <EventImage
                        src={image}
                        onClick={onOpenModal}
                    />
                    <DateContainer>
                        <DateSubTitle active={isSubtitleActive}>
                            {eventDate.format("MMMM")}
                        </DateSubTitle>
                        <DateTitle>
                            {EvDate}
                        </DateTitle>
                        <DateText>
                            {`${EvHour}:${EvMins}`} h
                        </DateText>
                    </DateContainer>
                </VisualsWrapper>
                <InfoContainer>
                    <InfoTitle
                        onClick={onOpenModal}
                    >
                        {title}
                    </InfoTitle>
                    <InfoParagraph>
                        {description.split("\n").map((text, index) => (
                            <span key={index}>
                                {text}
                                <br />
                            </span>
                        ))}
                    </InfoParagraph>

                </InfoContainer>
                <MainButton type="primary" onClick={onCall} styled="max-width: 40%">
                    {callToAction}
                </MainButton>
                <ArrowContainer
                    onClick={onShowBooks}
                    hasBooks={books.length > 0}
                    className={showBooks ? 'active' : ''}
                >
                    <i className="fas fa-chevron-down"></i>
                </ArrowContainer>
            </BasicWrapper>
            <SlideDown
                closed={!showBooks}
            >
                <BooksContainer>
                    {books.map((book, index) => (
                        <BookImage
                            key={index}
                            src={book}
                        />
                    ))}
                </BooksContainer>
            </SlideDown>
        </EventContainer>
    );
}
export default EventInfo;