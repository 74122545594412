import { createContext, useState, useEffect } from 'react';
// Helper
import { getLocation } from '../common/api-helper';
// Crear context
export const LocationContext = createContext();

// Creacion de provider
const LocationProvider = ({ children }) => {

    const [location, setLocation] = useState({});

    useEffect(() => {
        const getCurrentLocation = async () => {
            const thisLocation = await getLocation();
            setLocation({ ...thisLocation });
        };
        getCurrentLocation();
        return (() => setLocation({}));
    }, [])

    return (
        <LocationContext.Provider
            value={{
                location
            }}
        >
            {children}
        </LocationContext.Provider>
    );
}

export default LocationProvider;