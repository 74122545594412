import styled from '@emotion/styled';
// Componentes Generales
import FullScreenContainer from '../common/FullScreenContainer.styled';
// Colores
import {
    ColorMainBackground,
    ColorPrimary,
    ColorShadowDarker,
} from './../common/Pallete.const'

// Styled component del Carousel
export const HeroCarouselWrapper = styled(FullScreenContainer)`
    position: relative;
    height: calc(100vh);
    min-height: 315px;
    @media only screen and (min-width: 768px){
        height: 60vh;
        min-height: 380px;
        max-height: 800px;
    }
    @media only screen and (min-width: 1024px){
        height: 80vh;
        min-height: 490px;
    }
    @media only screen and (min-width: 1920px){
        height: 85vh;
    }
    background-image: url(${props => props.background});
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
    overflow-x: hidden;
`;
export const HeroSlidesWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    background-color: rgba(0, 0, 0, 0.2);
    overflow: hidden;
`;
export const HeroSlideContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
`;
export const HeroSlideContainerInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 60px;
    @media only screen and (min-width: 768px){
        width: 50vw;
        padding: 0 70px;
    }
    @media only screen and (min-width: 1024px){
        padding: 0 100px;
    }
    @media only screen and (min-width: 1920px){
        padding: 0 150px;
    }
`;
export const HeroSlideContainerInfoContainerTitle = styled.button`
    font-family: 'Shift-book', serif;
    text-align: left;
    font-weight: normal;
    font-size: 26px;
    width: 100%;
    transform: scale(1);
    transition: all 0.2s ease-in-out;
    &:hover{
        transform: scale(1.02);
        span{
            box-shadow: 0 6px 6px ${ColorShadowDarker};
        }
    }
    @media only screen and (min-width: 768px){
        font-size: 32px;
    }
    @media only screen and (min-width: 1024px){
        font-size: 45px;
        margin-bottom: 10px;
    }
    @media only screen and (min-width: 1920px){
        font-size: 70px;
        margin-bottom: 50px;
    }
    span{
        text-align: left!important;
        background-color: ${ColorPrimary};
        color: ${ColorMainBackground};
        padding: 5px 10px;
        line-height: 48px;
        transition: all 0.2s ease-in-out;
        box-shadow: 0 6px 6px transparent;
        @media only screen and (min-width: 768px){
            line-height: 55px;
        }
        @media only screen and (min-width: 1024px){
            line-height: 70px;
        }
        @media only screen and (min-width: 1920px){
            line-height: 110px;
        }
    }
`;
export const HeroSlideContainerInfoContainerText = styled.p`
    color: ${ColorMainBackground};
    margin-top: 20px;
    font-size: 15px;
    font-family: 'Shift-book', sans-serif;
    line-height: 1.2;
    @media only screen and (min-width: 768px){
        font-size: 18px;
    }
    @media only screen and (min-width: 1024px){
        font-size: 20px;
    }
    @media only screen and (min-width: 1920px){
        font-size: 25px;
    }
`;
export const HeroSlideContainerBookContainer = styled.button`
    display: none;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 45px 70px;
    width: 50vw;
    transform: scale(1);
    transition: all 0.2s ease-in-out;
    &:hover{
        transform: scale(1.02);
    }
    @media only screen and (min-width: 768px){
        display: flex;
    }
    @media only screen and (min-width: 1024px){
        padding: 60px 100px;
    }
`;
export const HeroSlideMainImage = styled.img`
    width: auto!important;
    height: 100%;
    max-height: 250px;
    @media only screen and (min-width: 768px){
        max-height: 270px;
    }
    @media only screen and (min-width: 1024px){
        max-height: 320px;
    }
    @media only screen and (min-width: 1920px){
        max-height: 550px;
    }
`;
export const HeroSlideContainerFestivalImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90%;
`;
/* Botones de navegacion */
export const HeroNavigationButton = styled.button`
    width: 20px;
    height: 20px;
    margin: 0 5px;
    border-radius: 100%;
    border: 2px solid transparent;
    position: relative;
    z-index: 3;
    cursor: pointer;
    &::before {
        position: absolute;
        content: '';
        width: 50%;
        height: 50%;
        background-color: ${ColorMainBackground};
        left: 25%;
        bottom: 25%;
        border-radius: 100%;
    }
    @media only screen and (min-width: 1024px){
        width: 25px;
        height: 25px;
    }
    @media only screen and (min-width: 1920px){
        width: 30px;
        height: 30px;
    }
    ${props => (props.active) ?
        `border-color: ${ColorPrimary};
        &::before{
            background-color: ${ColorPrimary};
        }
        ` : ''
    };
`;
