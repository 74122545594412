import { createContext, useState } from "react"
// Crear context
export const ModalContext = createContext();

// Creacion de Provider
const ModalProvider = ({ children }) => {
    const [cookiesAreAccepted, setCookiesAreAccepted] = useState(localStorage.getItem('cookiesAreAccepted') ?? false);
    const [showEventsDetails, setShowEventsDetails] = useState(false);

    return (
        <ModalContext.Provider
            value={{
                cookiesAreAccepted,
                setCookiesAreAccepted,
                showEventsDetails, 
                setShowEventsDetails
            }}
        >
            {children}
        </ModalContext.Provider>
    )
}

export default ModalProvider;