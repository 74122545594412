import { GetRequest, PostRequest } from '../utilities/const';
const kBackendUrl = process.env.REACT_APP_BACKEND_URL

// Solicitar ubicación API externa
export const getLocation = () => GetRequest(`https://ipapi.co/json/`);

//  Requesst GET al Backend
export const getHome = (countryCode) => GetRequest(`${kBackendUrl}/client.end.php?action=getHome&country=${countryCode}`);
export const getFestival = (id, countryCode) => GetRequest(`${kBackendUrl}/client.end.php?action=getFestival&id=${id}&country=${countryCode}`);
export const getCountries = () => GetRequest(`${kBackendUrl}/client.end.php?action=getCountries`);
export const getActivities = () => GetRequest(`${kBackendUrl}/client.end.php?action=getActivities`);
export const getEvent = (id) => GetRequest(`${kBackendUrl}/client.end.php?action=getEvent&idEvent=${id}`);
export const getStreamingId = (id, ipAddress, accessCode) => GetRequest(`${kBackendUrl}/client.end.php?action=getStreaming&idEvent=${id}&accessCode=${accessCode}&ipAddress=${ipAddress}`);
export const getStreamingsBetweenDates = (countryCode, dateTimeStart, dateTimeEnd, limit = 0) => GetRequest(`${kBackendUrl}/client.end.php?action=getStreamingsDate&dateTimeStart=${dateTimeStart}&dateTimeEnd=${dateTimeEnd}&countryCode=${countryCode}&limit=${limit}`);

// Request POST al Backend
export const subscribeNewsLetter = async (email, countryCode) => {
    // Datos del pais
    const data = new FormData();
    data.append('action', 'subscribeNewsLetter');
    data.append('email', email);
    data.append('country', countryCode);
    return (
        PostRequest(`${kBackendUrl}/client.end.php`, data)
    );
}

export const getSchedule = ({ idCountry, dateTimeStart, dateTimeEnd, keyWord, idActivity, idFestival }, countryCode) => {
    const data = new FormData();
    data.append('defaultCountry', countryCode);
    data.append('idCountry', idCountry);
    data.append('idActivity', idActivity);
    data.append('dateTimeStart', dateTimeStart);
    data.append('dateTimeEnd', dateTimeEnd);
    data.append('filterWord', keyWord);
    data.append('idFestival', idFestival);
    data.append('offset', 0);
    data.append('page', 0);
    data.append('action', 'getSchedule');

    return (
        PostRequest(`${kBackendUrl}/client.end.php`, data)
    );
}
export const getRemoveCode = (id, accessCode) => (
    GetRequest(`${kBackendUrl}/client.end.php?action=removePrivateCode&accessCode=${accessCode}&idEvent=${id}`)
)
