import styled from "@emotion/styled";
// Componentes Genericos
import FullScreenContainer from "../common/FullScreenContainer.styled";
import CustomScrollContainer from '../common/CustomScrollContainer';
// Colores
import {
    ColorPrimary,
    ColorDarkIcon,
    ColorPrimaryOpacity
} from "../common/Pallete.const";

export const ScheduleWrapper = styled(FullScreenContainer)`
    height: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 90px;
    @media only screen and (min-width: 768px){
        margin-bottom: 40px;
    }
    @media only screen and (min-width: 1024px){
        margin-top: 20px;
    }
`;
export const ScheduleWrapperTitle = styled.h5`
    width: 100%;
    text-align: center;
    font-weight: 800;
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 60px; 
    @media only screen and (min-width: 768px){
        font-size: 30px;
        margin-bottom: 80px; 
    }
    @media only screen and (min-width: 1024px){
        font-size: 50px;
    }
`;
export const ScheduleMainWrapper = styled.div`
    width: 100%; 
    display: flex;
    flex-direction: column;
`;
export const ScheduleNavTools = styled.div`
    width: 100%;
    background-color: ${ColorPrimary};
    padding: 10px 12px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    @media only screen and (min-width: 768px){
        justify-content: space-between;
        flex-direction: row;
        padding: 20px 50px;
    }
    @media only screen and (min-width: 1024px){
        padding: 20px 100px;
    }
    @media only screen and (min-width: 1920px){
        padding: 30px 120px;
    }
`;
export const SearchContainer = styled.div`
    position: relative;
    width: 100%;
    flex: 1;
    background-color: aliceblue;
    margin-bottom: 10px;
    border-radius: 3px;
    @media only screen and (min-width: 768px){
        flex: none;
        width: 40%;
        margin-bottom: 0;
    }
    @media only screen and (min-width: 1024px){
        width: 25%;
    }
`;
export const SearchInput = styled.input`
    font-family: 'Fort', sans-serif;
    outline: none;
    padding: 11px 25px 11px 15px;
    font-size: 12px;
    margin: 0;
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 3px;
    border: none;
    @media only screen and (min-width: 1024px){
        font-size: 15px;
        padding: 10px 35px 10px 25px;
    }
    @media only screen and (min-width: 1920px){
        font-size: 18px;
        padding: 15px 40px 15px 25px;
    }
`;
export const SearchIcon = styled.i`
    color: ${ColorDarkIcon};
    position: absolute;
    font-size: 15px;
    top: 9px;
    right: 7px;
    @media only screen and (min-width: 1024px){
        font-size: 15px;
        right: 10px;
        top: 12px; 
    }
    @media only screen and (min-width: 1920px){
        font-size: 18px;
        top: 15px; 
    }
`;
export const SelectsWrapper = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media only screen and (min-width: 768px){
        flex: none;
        width: 45%;
    }
    @media only screen and (min-width: 1024px){
        width: 40%;
    }
`;
export const SelectInput = styled.select`
    font-family: 'Fort', sans-serif;
    outline: none;
    padding: 10px 15px;
    font-size: 12px;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    width: 45%;
    @media only screen and (min-width: 500px){
        width: 40%;
    }
    @media only screen and (min-width: 768px){
        width: 45%;
    }
    @media only screen and (min-width: 1024px){
        font-size: 15px;
        padding: 10px 15px;
        width: 42%;
    }
    @media only screen and (min-width: 1920px){
        font-size: 18px;
        padding: 15px 25px;
    }
`;
export const SelectDateWrapper = styled(CustomScrollContainer)`
    background-color: ${ColorPrimaryOpacity};
    padding: 0 12px;
    display: flex;
    flex-direction: row;
    opacity: 1;
    transform: translateY(0);
    overflow-y: hidden;
    overflow-x: auto;
    visibility: visible;
    height: 45px;
    &.hide{
        visibility: hidden;
        height: 0;
    }
    @media only screen and (min-width: 768px){
        height: 70px;
        padding: 0 50px;
        overflow-y: visible;
        overflow-x: visible;
        &.overflow{
            overflow-y: hidden;
            overflow-x: auto;
        }
    }
    @media only screen and (min-width: 1024px){
        height: 75px;
        padding: 0 100px;
    }
    @media only screen and (min-width: 1920px){
        height: 85px;
        padding: 0 120px;
    }
`;
export const SelectDateCont = styled.div`
    padding: 16px 0;
    min-width: 40vw;
    background-color: transparent;
    font-family: 'Shift-Book', serif;
    font-weight: bold;
    font-size: 12px;
    color: ${ColorPrimary};
    text-align: center;
    border-right: white 2px solid;
    position: relative;
    cursor: pointer;
    text-transform: capitalize;
    transition: all ease-in-out 0.25s;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    &:hover{
        background-color: ${ColorPrimaryOpacity};
    }
    &.active{
        background-color: ${ColorPrimary};
        color: white;
    }
    &:first-of-type{
        border-left: white 2px solid;
    }
    &::after{
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: ${ColorPrimary};
        bottom: -10px;
        left: calc(50% - 10px);
        transform: rotate(45deg);
        display: none;
        z-index: -1;
    }
    @media only screen and (min-width: 500px){
        min-width: 27vw;
    }
    @media only screen and (min-width: 768px){
        min-width: auto;
        font-size: 16px;
        padding: 25px 0;
        flex: 1;
        &.fixed{
            min-width: 130px;
            &.active{
                &::after{ display: block; };
            }
        }
    }
    @media only screen and (min-width: 1024px){
        font-size: 20px;
        &.fixed{
            min-width: 150px;
        }
    }
    @media only screen and (min-width: 1920px){
        font-size: 24px;
        &.fixed{
            min-width: 190px;
        }
    }
    span{
        font-size: 90%;
        font-weight: normal;
        margin-top: 3px;
    }
`;
export const EventsWrapper = styled.div`
    display: flex;
    flex-direction:column;
    width: 100%;
    padding: 10px 20px;
    @media only screen and (min-width: 768px){
        padding: 10px 25px;
    }
`;

export const SpacerWrapper = styled.div`
    padding: 50px 0;
`;