import styled from '@emotion/styled';

export const NoContentWrapper = styled.div`
    flex-direction: column;
    display: flex;
    width: 100%;
    height: 75vh;
    justify-content: center;
`;
export const NoContentTitle = styled.h3`
    font-size: 3.5em;
    width: 100%;
    text-align: center;
    font-weight: bold;
    margin-bottom: 1.5em;
`;
export const NoContentSubTitle = styled.h3`
    font-size: 3em;
    width: 100%;
    text-align: center;
    font-weight: normal;
`;